import moment from 'moment'
import 'moment/locale/de'
import i18n from 'i18n-js'
// const en = require('./languages/en.json')
const de = require('./languages/de.json')
// const es = require('./languages/es.json')
const userLang = navigator.language || navigator.userLanguage; 

i18n.fallbacks = true
i18n.translations = { de }
i18n.locale = userLang
moment.locale(userLang)