import React from 'react';
import './App.css';
import Navigator from './navigation/Navigator'
import logo from './logo.png';
import { Layout, Menu, Icon, Popover, Button } from 'antd';
import { Link, BrowserRouter as Router } from "react-router-dom";
import firebase from './constants/firebase';
import './i18n/i18n';
import i18n from 'i18n-js';
import helper from './utils/helper';
import Messages from './components/Messages';
import UserAvatar from './components/UserAvatar';
// import Tour from 'reactour'
const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

// const steps = [
//   {
//     selector: '[data-tour="my-first-step"]',
//     content: ({ goTo, inDOM }) => (
//       <div>
//         Lorem ipsum
//         <br />
//         {inDOM && '🎉 Look at your step!'}
//       </div>
//     ),
//     position: 'right',
//     // you could do something like:
//     // position: [160, 250],
//     style: {
//       backgroundColor: '#fff',
//     },
//     // Disable interaction for this specific step.
//     // Could be enabled passing `true`
//     // when `disableInteraction` prop is present in Tour
//     stepInteraction: false,
//   },
//   {
//     selector: '[data-tour="my-second-step"]',
//     content: ({ goTo, inDOM }) => (
//       <div>
//         Lorem ipsum
//         <br />
//         {inDOM && '🎉 Look at your step!'}
//       </div>
//     ),
//     position: 'right',
//     // you could do something like:
//     // position: [160, 250],
//     style: {
//       backgroundColor: '#fff',
//     },
//     // Disable interaction for this specific step.
//     // Could be enabled passing `true`
//     // when `disableInteraction` prop is present in Tour
//     stepInteraction: false,
//   },
// ]

export default class App extends React.Component {
  state = {
    loading: true,
    userData: {},
    visible: false,
    // showTour: true
  };

  UNSAFE_componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.loadUserData(user.uid)
        this.loadCompanyDetails()
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });

    // const messaging = firebase.messaging();
    // messaging.usePublicVapidKey("BCstnRO1oC7uUirjIjFEVXCAsLpkIjAaSNjtZ97cHBxf7idHUftolOmLw4Bi6YJBcH70ndrfL-ESunzQJdr1EaM");

    // messaging.getToken().then((currentToken) => {
    //   if (currentToken) {
    //     // sendTokenToServer(currentToken);
    //     // updateUIForPushEnabled(currentToken);
    //   } else {
    //     // Show permission request.
    //     console.log('No Instance ID token available. Request permission to generate one.');
    //     // Show permission UI.
    //     // updateUIForPushPermissionRequired();
    //     // setTokenSentToServer(false);
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // showToken('Error retrieving Instance ID token. ', err);
    //   // setTokenSentToServer(false);
    // });

    // // Callback fired if Instance ID token is updated.
    // messaging.onTokenRefresh(() => {
    //   messaging.getToken().then((refreshedToken) => {
    //     console.log('Token refreshed.');
    //     // Indicate that the new Instance ID token has not yet been sent to the
    //     // app server.
    //     // setTokenSentToServer(false);
    //     // Send Instance ID token to app server.
    //     // sendTokenToServer(refreshedToken);
    //     // ...
    //   }).catch((err) => {
    //     console.log('Unable to retrieve refreshed token ', err);
    //     // showToken('Unable to retrieve refreshed token ', err);
    //   });
    // });

    // messaging.onMessage((payload) => {
    //   console.log('Message received. ', payload);

    //   notification.open({
    //     message: 'Notification Title',
    //     description:
    //       'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    //     onClick: () => {
    //       console.log('Notification Clicked!');
    //     },
    //   });
    // });
  }

  loadCompanyDetails = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .get()
      .then((doc) => {
        let companyData = {
          createdAt: doc.data().createdAt.toDate(),
          stripe: doc.data().stripe,
          userAmount: doc.data().userAmount
        }
        this.setState({
          companyData: companyData,
        })
      })
  }


  loadUserData = (uid) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    let userData = {}
    db.collection(`tenants/${tenant}/users`)
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.data()) {
          userData = {
            firstname: doc.data().firstname || "",
            lastname: doc.data().lastname || "",
            avatar: doc.data().avatar || "",
            email: doc.data().email || "",
            client: doc.data().client || undefined,
            isAdmin: doc.data().isAdmin || false,
            isManager: doc.data().isManager || false,
            isActive: doc.data().isActive || false,
            phone: doc.data().phone,
            id: doc.id
          }
          if (userData.client) {
            userData.client.get().then((snap) => {
              userData.client = snap.data()
              userData.clientId = snap.id
              this.setState({
                userData: userData,
                authenticated: true,
                loading: false
              })
            })
          } else {
            this.setState({
              userData: userData,
              authenticated: true,
              loading: false
            })
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // closeTour = () => {
  //   this.setState({showTour: false})
  // }

  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  render() {
    const { authenticated, loading, userData, companyData } = this.state

    if (loading || (authenticated && !companyData)) {
      return null
    }

    if (!authenticated) {
      return (
        <Router>
          <Layout className="layout" style={{display: 'flex', paddingTop: 50}}>
            <img src={logo} className="App-logo" alt="logo" style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 150 }} />
            <Content style={{ padding: '0 20px', maxWidth: 400, margin: 'auto' }}>
              <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                <Navigator authenticated={authenticated} currentUser={userData} companyData={companyData} />
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <Link to={{ pathname: '/imprint', query: { contentId: 8 } }} style={{paddingLeft: 10, color: '#888'}}>{i18n.t('imprint')}</Link>
              <Link to={{ pathname: '/data-protection', query: { contentId: 9 } }} style={{paddingLeft: 10, color: '#888'}}>{i18n.t('dataProtection')}</Link>
            </Footer>
          </Layout>
        </Router>
      )
    }

    return (
      // <StripeProvider apiKey="pk_live_9YXDw9WQQzzA3eILzXbvVemA00MF6EZpgZ">
      // <Elements>
      <Router>
        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0
            }}
          >
            <Menu 
              theme="dark" 
              mode="inline" 
              defaultOpenKeys={['admin', 'company']}
            >
              <Menu.Item key="1" data-tour="my-first-step">
                <Icon type="ordered-list" />
                <span>{i18n.t('overviewScreenHL')}</span>
                <Link to="/" />
              </Menu.Item>
              <Menu.Item key="2">
                <Icon type="issues-close" />
                <span>{i18n.t('claimsScreenHL')}</span>
                <Link to="/claims" />
              </Menu.Item>
              <Menu.Item key="22">
                <Icon type="plus" />
                <span>{i18n.t('addClaim')}</span>
                <Link to="/addClaim" />
              </Menu.Item>
              {userData.isManager &&
                <Menu.Item key="3">
                  <Icon type="wechat" />
                  <span>{i18n.t('chatScreenHL')}</span>
                  <Link to="/chat" />
                </Menu.Item>
              }
              {(userData.isManager && !userData.client.private) &&
                <SubMenu
                  key="company"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>{i18n.t('settingsScreenHL')}</span>
                    </span>
                  }
                >
                  <Menu.Item key="4">
                    <Link to={`/manager/company/${userData.clientId}`}>{i18n.t('manageCompany')}</Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link to="/manager/employees">{i18n.t('manageEmployees')}</Link>
                  </Menu.Item>
                </SubMenu>
              }
              {userData.isAdmin && [
                <Menu.Item key="312">
                  <Icon type="wechat" />
                  <span>{i18n.t('companyChat')}</span>
                  <Link to="/admin/internalChat" />
                </Menu.Item>,
                <Menu.Item key="322">
                  <Icon type="check-circle" />
                  <span>{i18n.t('tasks')}</span>
                  <Link to="/admin/internalTasks" />
                </Menu.Item>
              ]}
              {userData.isAdmin &&
                <SubMenu
                  key="company"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>{i18n.t('settingsScreenHL')}</span>
                    </span>
                  }
                >
                  <Menu.Item key="4">
                    <Link to="/admin/company/">{i18n.t('manageCompany')}</Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link to="/admin/employees">{i18n.t('manageEmployees')}</Link>
                  </Menu.Item>
                </SubMenu>
              }
              {userData.isAdmin &&
                <SubMenu
                  key="admin"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>{i18n.t('administration')}</span>
                    </span>
                  }
                >
                  <Menu.Item key="5">
                    <Link to="/admin/clients">{i18n.t('manageClients')}</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="7">
                    <Link to="/admin/abo">{i18n.t('manageAbo')}</Link>
                  </Menu.Item> */}
                </SubMenu>
              }
              {/* <SubMenu
                key="help"
                title={
                  <span>
                    <Icon type="question" />
                    <span>{i18n.t('help')}</span>
                  </span>
                }
              >
                <Menu.Item key="9">
                  <Link to={{ pathname: '/help', query: { contentId: 12 } }}>{i18n.t('help')}</Link>
                </Menu.Item>
              </SubMenu> */}
              <SubMenu
                key="legal"
                title={
                  <span>
                    <Icon type="copyright" />
                    <span>{i18n.t('legal')}</span>
                  </span>
                }
              >
                <Menu.Item key="10">
                  <Link to={{ pathname: '/imprint', query: { contentId: 8 } }}>{i18n.t('imprint')}</Link>
                </Menu.Item>
                <Menu.Item key="11">
                  <Link to={{ pathname: '/data-protection', query: { contentId: 9 } }}>{i18n.t('dataProtection')}</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout>
            <Header>
              <img src={logo} className="App-logo" alt="logo" style={{ marginLeft: 'auto' }} />

              <Messages
                currentUser={userData}
              />

              <Popover
                content={
                  <div>
                    <div style={{marginBottom: 5}}><Link to="/profile">{i18n.t('profile')}</Link></div>
                    <a href="#!" onClick={() => helper.logOutUser(this.props.history)}>{i18n.t('logOut')}</a>
                  </div>
                }
                title={
                  <div>
                    <div>{userData.firstname} {userData.lastname}</div>
                    <small>{(userData.isManager && !userData.client.private) ? 'Manager' : userData.isAdmin ? 'Administrator' : ''}</small>
                  </div>
                }
                placement="bottomRight"
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
              >
                <Button type="link" ghost style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <UserAvatar
                    size="30"
                    name={`${userData.firstname} ${userData.lastname}`}
                    src={userData.avatar}
                  />
                </Button>
              </Popover>

            </Header>
            <Content>
              <Navigator authenticated={authenticated} currentUser={userData} companyData={companyData} />
              {/* <Tour
                steps={steps}
                isOpen={showTour}
                onRequestClose={this.closeTour} /> */}
            </Content>
          </Layout>
        </Layout>
      </Router>
      // </Elements>
      // </StripeProvider>
    );
  }
}