import React from 'react';
import { Button, Form, Modal, Switch, Input } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import { firebaseConfig } from '../constants/firebase';

class EmployeesModal extends React.Component {
  state = {
    loading: true
  }

  updateData = (values) => {
    const tenant = localStorage.getItem('tenant');
    const { item } = this.props
    const db = firebase.firestore()
    let newUserData = {}

    if (values.isManager !== undefined) {
      newUserData.isManager = values.isManager
    }
    if (values.isActive !== undefined) {
      newUserData.isActive = values.isActive
    }

    if (values.email) {
      newUserData.email = values.email
    }
    if (values.firstname) {
      newUserData.firstname = values.firstname
    }
    if (values.lastname) {
      newUserData.lastname = values.lastname
    }
    if (values.street) {
      newUserData.street = values.street
    }
    if (values.zip) {
      newUserData.zip = values.zip
    }
    if (values.city) {
      newUserData.city = values.city
    }
    if (values.phone) {
      newUserData.phone = values.phone
    }

    return db.collection(`tenants/${tenant}/users`).doc(item.id).set(newUserData, {merge: true}).then(() => {
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
  }

  addEmployee = (values) => {
    const tenant = localStorage.getItem('tenant');
    const { clientId } = this.props
    const db = firebase.firestore()
    

    const authApp = firebase.initializeApp(firebaseConfig, 'authApp')
    const detachedAuth = authApp.auth()
    return detachedAuth.createUserWithEmailAndPassword(values.email, values.password).then(async(data) => {
      const user = await authApp.auth().currentUser
      let newUserData = {
        id: user.uid,
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        street: values.street,
        zip: values.zip,
        city: values.city,
        phone: values.phone,
        isActive: true,
        isAdmin: !clientId ? true : false,
        isManager: values.isManager || false,
        tenant: tenant
      }

      if (clientId) {
        const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)
        newUserData.client = clientRef
      }

      try {
        return db.collection(`tenants/${tenant}/users`)
        .doc(user.uid)
        .set(newUserData)
        .then(() => {
          this.setState({saving: false})
          authApp.delete()
          this.props.toogleModal(false)
        })
      } catch (error) {
        this.setState({saving: false})
        console.log(error)
      }
      
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({saving: true})
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateData(values)
        } else {
          this.addEmployee(values)
        }
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item, edit, clientId } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editEmployeeScreenHL') : i18n.t('addEmployee')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('firstname')}>
            {getFieldDecorator('firstname', {
              valuePropName: 'firstname',
              rules: [{ required: true }],
              initialValue: (item) ? item.firstname : '',
            })(
              <Input
                placeholder={i18n.t('firstname')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('lastname')}>
            {getFieldDecorator('lastname', {
              valuePropName: 'lastname',
              rules: [{ required: true }],
              initialValue: (item) ? item.lastname : '',
            })(
              <Input
                placeholder={i18n.t('lastname')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('street')}>
            {getFieldDecorator('street', {
              valuePropName: 'street',
              rules: [{ required: true }],
              initialValue: (item) ? item.street : '',
            })(
              <Input
                placeholder={i18n.t('street')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('zip')}>
            {getFieldDecorator('zip', {
              valuePropName: 'zip',
              rules: [{ required: true }],
              initialValue: item ? item.zip : '',
            })(
              <Input
                placeholder={i18n.t('zip')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('city')}>
            {getFieldDecorator('city', {
              valuePropName: 'city',
              rules: [{ required: true }],
              initialValue: (item) ? item.city : '',
            })(
              <Input
                placeholder={i18n.t('city')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('phone')}>
            {getFieldDecorator('phone', {
              valuePropName: 'phone',
              rules: [{ required: true }],
              initialValue: (item) ? item.phone : '',
            })(
              <Input
                placeholder={i18n.t('phone')}
              />,
            )}
          </Form.Item>

          {!edit &&
            <Form.Item label={i18n.t('email')}>
              {getFieldDecorator('email', {
                valuePropName: 'email',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('email')}
                />,
              )}
            </Form.Item>
          }
          {!edit &&
            <Form.Item label={i18n.t('password')}>
              {getFieldDecorator('password', {
                valuePropName: 'password',
                rules: [{ required: true }],
              })(
                <Input
                  type="password"
                  placeholder={i18n.t('password')}
                />,
              )}
            </Form.Item>
          }

          {edit &&
          <Form.Item label={i18n.t('isActive')}>
            {getFieldDecorator('isActive', {
              valuePropName: 'checked',
              rules: [{ required: true }],
              initialValue: (item) ? item.isActive : false,
            })(
              <Switch />,
            )}
          </Form.Item>
          }

          {clientId &&
            <Form.Item label={i18n.t('isManager')}>
              {getFieldDecorator('isManager', {
                valuePropName: 'checked',
                rules: [{ required: true }],
                initialValue: (item) ? item.isManager : false,
              })(
                <Switch />,
              )}
            </Form.Item>
          }
          
          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="employees-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'employees_modal' })(EmployeesModal);