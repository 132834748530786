import React from 'react';
import { PageHeader } from 'antd';
import Chat from '../Chat/Chat';

export default class Comments extends React.Component {
  render() {
    const { active } = this.props

    return ([
      <PageHeader 
        key="11" 
      />,
      <Chat
        key="22"
        chat={`claims/${this.props.claim}/comments`}
        clientId={this.props.clientId}
        active={active}
      />
    ])
  }
}