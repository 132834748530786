import React from 'react'
import { Form, Icon, Input, Button, Alert, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class ForgotPassword extends React.Component {
  state = {
    tenant: '',
    error: ''
  }

  componentDidMount = () => {
    const tenant = localStorage.getItem('tenant');
    if (!tenant) {
      return this.props.history.push('/choose-workspace')
    }
  }

  forgotPassword = (email) => {
    this.setState({fetching: true})
    try {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(res => {
          message.info(i18n.t('PasswordResetEmail'));
        })
        .catch((error) => {
          this.setState({fetching: false, error: error.toString()})
        })
    } catch (error) {
      this.setState({fetching: false, error: error.toString()})
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.forgotPassword(values.email)
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="forgotPassword-form">
        {/* <h1>{i18n.t('productName')}</h1>
        <p>{i18n.t('productClaim')}</p> */}
        <h3>{i18n.t('forgotPasswordText')}</h3>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('email')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
            {i18n.t('send')}
          </Button>
          {/* <Link to="/login">{i18n.t('signin')}</Link> */}
        </Form.Item>
        {error && 
          <Alert message={error} type="error" />
        }
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_login' })(ForgotPassword);
