export const types = [
    {id: 1, label: 'KFZ'},
    {id: 2, label: 'Gebäude'},
    {id: 3, label: 'Betriebshaftpflicht'},
    {id: 4, label: 'Privathaftpflicht'},
    {id: 5, label: 'Vermögensschadenhaftpflicht'},
    {id: 6, label: 'Grundstückshaftpflicht'},
    {id: 7, label: 'Inventar'},
    {id: 8, label: 'Ertragsausfall'},
    {id: 9, label: 'Technische - Maschinen'},
    {id: 10, label: 'Technische - Geräte'},
    {id: 11, label: 'Technische - Elektronik'}
]

export const kfz = [
    {id: 1, label: 'Haftpflicht'},
    {id: 2, label: 'Teilkasko'},
    {id: 3, label: 'Vollkasko'},
    {id: 4, label: 'Wild- oder Tierschaden'},
    {id: 5, label: 'Marder- oder Tierbiss'},
    {id: 6, label: 'Diebstahl'},
    {id: 7, label: 'Glasschaden'},
    {id: 8, label: 'Sturm'},
    {id: 9, label: 'Feuer'},
    {id: 10, label: 'Sonstiges'}
]

export const kfzTypes = [
    {id: 1, label: 'Kasko'},
    {id: 2, label: 'Haftpflicht'}
]

export const building = [
    {id: 1, label: 'Leitungswasser'},
    {id: 2, label: 'Feuer'},
    {id: 3, label: 'Sturm'},
    {id: 4, label: 'Hagel'},
    {id: 5, label: 'Überspannungsschaden'},
    {id: 6, label: 'Elementarschaden'},
    {id: 7, label: 'Regenrückstauschaden'},
    {id: 8, label: 'Rohrverstopfung'},
    {id: 9, label: 'Graffiti'},
    {id: 10, label: 'Glas'},
    {id: 11, label: 'Haftpflicht - Haus und Grundbesitzer'},
    {id: 12, label: 'Haftpflicht - Öltank'},
    {id: 13, label: 'Sonstiges'}
]

export const stepsLabel = [
    {
        id: 1, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
            {id: 3, label: 'Fahrzeugführer'},
            {id: 4, label: 'Unfallgegner'},
        ],
    },
    {
        id: 2, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    },
    {
        id: 3, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
            {id: 3, label: 'Geschädigter'},
        ]
    },
    {
        id: 4, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
            {id: 3, label: 'Geschädigter'},
        ]
    },
    {
        id: 5, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
            {id: 3, label: 'Geschädigter'},
        ]
    },
    {
        id: 6, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
            {id: 3, label: 'Geschädigter'},
        ]
    },
    {
        id: 7, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    },
    {
        id: 8, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    },
    {
        id: 9, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    },
    {
        id: 10, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    },
    {
        id: 11, 
        steps: [
            {id: 1, label: 'Schadenart'},
            {id: 2, label: 'Schadenhergang'},
        ]
    }
]

export default {
    types,
    kfz,
    building,
}