import React from 'react';
import { List, Popover, Badge, Icon, Button } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import { sortBy } from 'lodash'
import { withRouter } from 'react-router-dom'

class Messages extends React.Component {
  state = {
    loading: true,
    messages: []
  }

  componentDidMount = () => {
    if (this.props.currentUser.clientId) {
      this.loadMessagesForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.isAdmin) {
      this.loadAllMessages()
    }
  }

  loadMessagesForClient = (clientId) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    db.collection(`tenants/${tenant}/clients/${clientId}/messages`)
      .orderBy('createdAt', 'desc')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        let message = {
          message: item.data().message,
          createdAt: item.data().createdAt,
          uid: item.id,
          type: item.data().type,
          createdBy: item.data().createdBy,
          data: item.data().data,
          read: item.data().read
        }
        if (item.data().createdBy._id) {
          message.createdBy = item.data().createdBy.name
          m.push(message)
          this.setState({
            messages: m,
          })
        } else {
          message.createdBy.get().then((snap) => {
            message.createdBy = snap.data().firstname + ' ' + snap.data().lastname
            m.push(message)
            this.setState({
              messages: m,
            })
          })
        }
        
      })
    })
  }

  loadAllMessages = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    db.collection(`tenants/${tenant}/messages`)
      .orderBy('createdAt', 'desc')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        let message = {
          message: item.data().message,
          createdAt: item.data().createdAt,
          uid: item.id,
          type: item.data().type,
          createdBy: item.data().createdBy,
          data: item.data().data,
          read: item.data().read
        }
        if (item.data().createdBy._id) {
          message.createdBy = item.data().createdBy.name
          m.push(message)
          this.setState({
            messages: m,
          })
        } else if (item.data().createdBy) {
          message.createdBy.get().then((snap) => {
            message.createdBy = snap.data().firstname + ' ' + snap.data().lastname
            m.push(message)
            this.setState({
              messages: m,
            })
          })
        } else {
          this.setState({
            messages: m,
          })
        }
        
      })
    })
  }

  handleMessagesVisibleChange = messagesVisible => {
    this.setState({ messagesVisible });
  }

  openMessage = async (item) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant')
    if (this.props.currentUser.clientId) {
      await db.collection(`tenants/${tenant}/clients/${this.props.currentUser.clientId}/messages`).doc(item.uid).update({read: true})
    } else if (this.props.currentUser.isAdmin) {
      await db.collection(`tenants/${tenant}/messages`).doc(item.uid).update({read: true})
    }
    
    let target = ''
    switch (item.type) {
      case 'newClient':
        target = `/admin/clients/${item.data.target}`
        break;
      case 'newTask':
        target = `/admin/internalTasks`
        break;
      case 'claimUpdated':
        target = `/claims/${item.data.target}`
        break;
      case 'claim':
        target = `/claims/${item.data.target}`
        break;
      case 'comment':
        target = `/claims/${item.data.target}`
        break;
      case 'document':
        target = `/claims/${item.data.target}`
        break;
      case 'groupChat':
        target = `/claims/${item.data.target}`
        break;
      default:
        break;
    }
    this.props.history.push(target);
  }

  render() {
    const { messages } = this.state
    const sortedMessages = sortBy(messages, [function(o) { return o.createdAt.toDate(); }]).reverse()
    const messageAmount = messages.filter(item => !item.read).length

    return (
      <Popover
        content={
          <List
            size="small"
            dataSource={sortedMessages}
            renderItem={(item, index) => 
              <List.Item key={index}>
                {!item.read &&
                  <Badge status="error" /> 
                }
                <Button type="link" onClick={() => this.openMessage(item)}>
                  {item.createdBy} {i18n.t(item.message, {name: item.data.label})} - {moment(item.createdAt.toDate()).fromNow()}
                </Button>
              </List.Item>
            }
          />
        }
        placement="bottomRight"
        trigger="click"
        visible={this.state.messagesVisible}
        onVisibleChange={this.handleMessagesVisibleChange}
      >
        <div style={{marginLeft: 'auto', marginRight: 20, marginTop: 8, cursor: 'pointer'}}>
          <Badge count={messageAmount}>
            <Icon type="notification" style={{fontSize: 20}} />
          </Badge>
        </div>
      </Popover>
    )
  }
}

export default withRouter(Messages)