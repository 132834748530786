import React from 'react';
import { PageHeader, Descriptions, Typography, Divider } from 'antd';
import i18n from 'i18n-js';
import moment from 'moment';
import helper from '../../utils/helper';
import { types } from '../../constants/insurances';
import firebase from '../../constants/firebase';
const { Title } = Typography;

export default class Informations extends React.Component {
  state = {
    loading: true,
    employees: []
  }

  componentDidMount = () => {
    if (this.props.currentUser.clientId) {
      this.loadEmployeesForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.isAdmin) {
      this.loadAdllEmployees()
    }
  }

  loadEmployeesForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/users`)
      .where('client', '==', clientRef)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  loadAdllEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  getLabelForClaimType = (type) => {
    const label = types.filter(item => item.id === type)[0]
    return label.label
  }

  getNameForDriverId = (id) => {
    const { employees } = this.state
    const driver = employees.filter(item => item.id === id)[0]
    if (!driver) {
      return null
    }
    return `${driver.firstname} ${driver.lastname}`
  }

  render() {
    const { claim } = this.props

    return ([
      <PageHeader 
        key="11" 
      />,
      <div key="22">
        <Descriptions size="medium" column={1}>
          <Title level={2}>Schadenhergang</Title>,
        
          <Descriptions.Item label={i18n.t('claimDate')}>
            {moment(claim.date).format('DD.MM.YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('claimTime')}>
            {moment(claim.time).format('HH:mm')}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('damageDescription')}>
            {claim.info}
          </Descriptions.Item>


          {claim.insurance.type === 1 && [
            <Divider />,
            <Title level={2}>Fahrzeugführer</Title>,
            <Descriptions.Item label={i18n.t('type')}>
              {this.getLabelForClaimType(claim.claimType)}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('policeReference')}>
              {claim.policeReference}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('claimLocation')}>
              {claim.claimLocation}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('claimViolated')}>
              {claim.claimViolated}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('claimAlcohol')}>
              {claim.claimAlcohol ? 'Ja' : 'Nein'}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('claimLicence')}>
              {claim.claimLicence ? 'Ja' : 'Nein'}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('claimRemoved')}>
              {claim.claimRemoved ? 'Ja' : 'Nein'}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('driver')}>
              {this.getNameForDriverId(claim.driver)}
            </Descriptions.Item>,

            <Divider />,
            <Title level={2}>{i18n.t('opponent')}</Title>,
            <Descriptions.Item label={i18n.t('opponentNumberPlate')}>
              {claim.opponentNumberPlate}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentInsuranceCompany')}>
              {claim.opponentInsuranceCompany}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentInsuranceNumber')}>
              {claim.opponentInsuranceNumber}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentFirstname')}>
              {claim.opponentFirstname}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentLastname')}>
              {claim.opponentLastname}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentStreet')}>
              {claim.opponentStreet}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentZip')}>
              {claim.opponentZip}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentCity')}>
              {claim.opponentCity}
            </Descriptions.Item>,
            <Descriptions.Item label={i18n.t('opponentPhone')}>
              {claim.opponentPhone}
            </Descriptions.Item>
          ]}

          {claim.insurance.type === 2 && [
            <Descriptions.Item key="123" label={i18n.t('renter')}>
              {claim.renter}
            </Descriptions.Item>,
            <Descriptions.Item key="234" label={i18n.t('roughEstimate')}>
              {helper.formatEuro(claim.roughEstimate)}
            </Descriptions.Item>
          ]}

          {claim.insurance.type > 2 && [
            <Descriptions.Item key="345" label={i18n.t('roughEstimate')}>
              {helper.formatEuro(claim.roughEstimate)}
            </Descriptions.Item>
          ]}

          {(claim.insurance.type > 2 && claim.insurance.type < 7)&& [
            <Divider key="11" />,
            <Title key="21" level={2}>{i18n.t('opponentSoft')}</Title>,
            <Descriptions.Item key="31" label={i18n.t('opponentFirstname')}>
              {claim.opponentFirstname}
            </Descriptions.Item>,
            <Descriptions.Item key="41" label={i18n.t('opponentLastname')}>
              {claim.opponentLastname}
            </Descriptions.Item>,
            <Descriptions.Item key="51" label={i18n.t('opponentStreet')}>
              {claim.opponentStreet}
            </Descriptions.Item>,
            <Descriptions.Item key="61" label={i18n.t('opponentZip')}>
              {claim.opponentZip}
            </Descriptions.Item>,
            <Descriptions.Item key="71" label={i18n.t('opponentCity')}>
              {claim.opponentCity}
            </Descriptions.Item>,
            <Descriptions.Item key="81" label={i18n.t('opponentPhone')}>
              {claim.opponentPhone}
            </Descriptions.Item>
          ]}

          {claim.insurance.type > 8 && [
            <Descriptions.Item label={i18n.t('claimMaschine')}>
              {claim.claimMaschine}
            </Descriptions.Item>
          ]}

        </Descriptions>
      </div>
    ])
  }
}