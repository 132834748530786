import firebase from '../constants/firebase';

const isEmpty = (obj) => { return Object.keys(obj).length === 0 }

const getAcronym = (fullName) => {
  const matches = fullName.match(/\b(\w)/g)
  const acronym = matches.join('')
  const shortName = acronym.slice(0,2)
  return shortName.toUpperCase()
}

const formatEuro = (number) => {
  return number ? `${Number(number).toLocaleString("de-DE", {minimumFractionDigits: 2})} €` : '';
}

const logOutUser = async(history) => {
  try {
    await firebase.auth().signOut();
    return history.push('/')
  } catch (e) {
    console.log(e)
  } 
}

export default {
  isEmpty,
  getAcronym,
  logOutUser,
  formatEuro
}