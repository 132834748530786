import React from 'react';
import Chat from '../components/Chat/Chat';
import { PageHeader } from 'antd';
import i18n from 'i18n-js';

export default class GroupChat extends React.Component {
  render() {
    return ([
      <PageHeader
        key="11" 
        title={i18n.t('chatScreenHL')}
        subTitle={i18n.t('chatScreenSL')}
      />,
      <Chat
        key="22"
        chat={`clients/${this.props.currentUser.clientId}/groupChat`}
        active={true}
      />
    ]);
  }
}