import React from 'react';
import { Steps, PageHeader, Form, Select, Button, Input } from 'antd';
import i18n from 'i18n-js';
import AddClaimForm from '../components/Claim/AddClaimForm';
import firebase from '../constants/firebase';
import { stepsLabel, kfzTypes, types } from '../constants/insurances';
import helper from '../utils/helper';
const { Step } = Steps;
const { Option } = Select;

export default class AddClaim extends React.Component {
  state = {
    current: 0,
    insurances: [],
    employees: [],
    type: 0,
    stepsForType: [{
      id: 1,
      label: 'Schadenart'
    }],
    policeReference: '',
    claimType: 0,
  }

  componentDidMount = async () => {
    if (this.props.currentUser.clientId) {
      this.loadInsurancesForClient(this.props.currentUser.clientId)
      this.loadEmployeesForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.isAdmin) {
      this.loadAllInsurances()
      this.loadAllEmployees()
    }
  }

  loadInsurancesForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/insurances`)
      .where('client', '==', clientRef)
      .where('active', '==', true)
      .orderBy('type', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let insurances = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            type: doc.data().type,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            name: doc.data().name,
            fully: doc.data().fully,
            partially: doc.data().partially,
            driven: doc.data().driven,
            registeredAt: doc.data().registeredAt,
            deRegisteredAt: doc.data().deRegisteredAt,
            zip: doc.data().zip,
            city: doc.data().city
          }
          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id
            insurances.push(item)
            this.setState({
              insurances: insurances,
              loading: false
            })
          })
        })
      })
  }

  loadEmployeesForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/users`)
      .where('client', '==', clientRef)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            client: doc.data().client
          }
          if (item.client) {
            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
              employees.push(item)
              this.setState({
                employees: employees,
                loading: false
              })
            })
          } else {
            employees.push(item)
            this.setState({
              employees: employees,
              loading: false
            })
          }
        })
      })
  }

  loadAllEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            client: doc.data().client
          }
          if (item.client) {
            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
              employees.push(item)
              this.setState({
                employees: employees,
                loading: false
              })
            })
          } else {
            employees.push(item)
            this.setState({
              employees: employees,
              loading: false
            })
          }
          
        })
      })
  }

  loadAllInsurances = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/insurances`)
      .where('active', '==', true)
      .orderBy('type', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let insurances = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            type: doc.data().type,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            name: doc.data().name,
            fully: doc.data().fully,
            partially: doc.data().partially,
            driven: doc.data().driven,
            registeredAt: doc.data().registeredAt,
            deRegisteredAt: doc.data().deRegisteredAt,
            zip: doc.data().zip,
            city: doc.data().city
          }
          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id
            insurances.push(item)
            this.setState({
              insurances: insurances,
              loading: false
            })
          })
        })
      })
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onDropDownChange = (item) => {
    const type = item.split('__')[0]
    const typeId = item.split('__')[1]
    const clientId = item.split('__')[2]
    const stepsForType = stepsLabel.filter(item => item.id === Number(type))[0]
    this.setState({
      type: type,
      typeId: typeId,
      clientId: clientId,
      stepsForType: stepsForType.steps
    })
  }

  onCreatorDropDownChange = (item) => {
    this.setState({
      creator: item
    })
  }

  onSecondAssigneeDropDownChange = (item) => {
    this.setState({
      secondAssignee: item
    })
  }

  onTypeDropDownChange = (item) => {
    this.setState({
      claimType: item
    })
  }

  onPoliceReferenceChange = (item) => {
    this.setState({
      policeReference: item.nativeEvent.data
    })
  }

  getTypeLabel = (type, name, client) => {
    let label = types.filter(item => item.id === type)[0]
    let nameText
    if (type > 2) {
      nameText = helper.formatEuro(name)
    } else {
      nameText = name
    }
    if (this.props.currentUser.isAdmin) {
      label = `${client.company} | ${label.label}`
    } else {
      label = `${label.label}`
    }
    return (<span>{`${label} | ${nameText}`}</span>)
  }

  render() {
    const { current, insurances, stepsForType, type, claimType, typeId, clientId, policeReference, employees, creator, secondAssignee } = this.state;
    
    return (
      <div>
        <PageHeader
          key="11" 
          title={i18n.t('addClaim')} 
        />

        <Steps current={current} style={{marginBottom: 25}}>
          {stepsForType.map(item => (
            <Step key={item.id} title={item.label} />
          ))}
        </Steps>

        {(current === 0) &&
          <div>
            <Form.Item label={i18n.t('claimCreator')}>
              <Select placeholder={i18n.t('claimCreator')} onChange={(e) => this.onCreatorDropDownChange(e)} style={{width: 400}}>
                <Option key={this.props.currentUser.id} value={this.props.currentUser.id}>Ich</Option>
                {employees.map((item) => (
                  <Option key={item.id} value={item.id}>{`${item.client ? item.client.company + '|' : ''} ${item.firstname} ${item.lastname}`}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={i18n.t('claimSecondAssignee')}>
              <Select placeholder={i18n.t('claimSecondAssignee')} onChange={(e) => this.onSecondAssigneeDropDownChange(e)} style={{width: 400}}>
                <Option key={this.props.currentUser.id} value={this.props.currentUser.id}>Ich</Option>
                {employees.map((item) => (
                  <Option key={item.id} value={item.id}>{`${item.client ? item.client.company + '|' : ''} ${item.firstname} ${item.lastname}`}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={i18n.t('selectDamagedObject')}>
              <Select placeholder={i18n.t('selectDamagedObject')} onChange={(e) => this.onDropDownChange(e)} style={{width: 400}}>
                {insurances.map((item) => (
                  <Option key={item.id} value={`${item.type}__${item.id}__${item.clientId}`}>{this.getTypeLabel(item.type, item.name, item.client)}</Option>
                ))}
              </Select>
            </Form.Item>

            {type === '1' &&
              <div>
                <Form.Item label={i18n.t('type')}>
                  <Select placeholder={i18n.t('type')} onChange={(e) => this.onTypeDropDownChange(e)} style={{width: 400}}>
                    {kfzTypes.map((item) => (
                      <Option key={item.id} value={item.id}>{`${item.label}`}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={i18n.t('policeReference')} style={{width: 400}}>
                  <Input
                    placeholder={i18n.t('policeReference')}
                    onChange={(e) => this.onPoliceReferenceChange(e)}
                  />
                </Form.Item>
              </div>
            }

            {type > 0 &&
              <Button type="primary" onClick={() => this.next()}>
                {i18n.t('next')}
              </Button>
            }
          </div>
        }

        {current > 0 &&
          <div className="steps-content">
            <AddClaimForm
              stepsForType={stepsForType}
              step={stepsForType.length - 1}
              next={() => this.next()}
              type={type}
              clientId={clientId}
              typeId={typeId}
              claimType={claimType}
              prev={() => this.prev()}
              current={current}
              policeReference={policeReference}
              history={this.props.history}
              creator={creator}
              secondAssignee={secondAssignee}
            />
          </div>
        }
        
      </div>
    );
  }
}