import React from 'react';
import { PageHeader, List, Avatar, Button, message, Popconfirm } from 'antd';
import firebase from '../constants/firebase';
import TasksModal from '../modal/TasksModal';
import moment from 'moment';
import i18n from 'i18n-js';
import UserAvatar from '../components/UserAvatar';

export default class CompanyTasks extends React.Component {
  state = {
    loading: true,
    tasks: []
  }

  componentDidMount = () => {
    this.loadTasks()
  }

  loadTasks = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    db.collection(`tenants/${tenant}/tasks`)
      .orderBy('done')
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        let taskData = {
          name: item.data().name,
          createdAt: item.data().createdAt,
          uid: item.id,
          done: item.data().done,
          containsDeadline: item.data().containsDeadline,
          containsAssignee: item.data().containsAssignee,
          notes: item.data().notes,
          assignee: item.data().assignee
        }

        if (item.data().deadline) {
          taskData.deadline = item.data().deadline
        }

        if (taskData.assignee) {
          taskData.assignee.get().then((snap) => {
            taskData.assignee = snap.data()
            taskData.assignee.id = snap.id
            m.push(taskData)
            this.setState({
              tasks: m,
            })
          })
        } else {
          m.push(taskData)
          this.setState({
            tasks: m,
          })
        }
      })
      this.setState({tasks: m, loading: false})
    })
  }

  toggleItemStatus = (item) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const ref = db.collection(`tenants/${tenant}/tasks`).doc(item.uid)

    return ref.update({
      done: !item.done
    })
  }

  removeItem = (itemId) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const ref = db.collection(`tenants/${tenant}/tasks`).doc(itemId)
    ref.delete()
    .then(function() {
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  toogleModal = (val, edit, item) => {
    if (edit) {
      this.setState({
        containsDeadline: item.containsDeadline,
        containsAssignee: item.containsAssignee,
        modalVisible: val,
        edit: edit,
        item: item,
        taskItem: item.name,
        notes: item.notes
      })
    } else {
      this.setState({modalVisible: val, edit: edit, item: item})
    }
  }

  render() {
    const { tasks, loading, modalVisible, edit, item } = this.state

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('tasks')} 
        extra={[
          <Button key="1" type="primary" icon="plus" onClick={() => this.toogleModal(true)}>
            {i18n.t('addTaskScreenHL')}
          </Button>,
        ]}
      />,
      <List
        key="22"
        itemLayout="horizontal"
        dataSource={tasks}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              <a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>, 
              <Popconfirm
                title={i18n.t('areYouSure')}
                onConfirm={() => this.removeItem(item.uid)}
                okText={i18n.t('yes')}
                cancelText={i18n.t('no')}
              >
                <a href="#!">{i18n.t('delete')}</a>
              </Popconfirm>
            ]}
          >
            <List.Item.Meta
              avatar={(item.done) ? <Avatar icon="check" onClick={() => this.toggleItemStatus(item)} /> : <Avatar onClick={() => this.toggleItemStatus(item)}> </Avatar>}
              title={item.name}
              description={(item.containsDeadline) ? <span style={{color: (new Date(moment(item.deadline.toDate()).format('YYYY-MM-DD')) <= new Date() && !item.done) ? 'red' : 'inherit'}}>{moment(item.deadline.toDate()).format('DD.MM.YY')}</span> : ''}
            />
            <div>
              {item.containsAssignee &&
                <UserAvatar
                  size="30"
                  name={`${item.assignee.firstname} ${item.assignee.lastname}`}
                  src={item.assignee.avatar}
                />
              }
            </div>
          </List.Item>
        )}
      />,
      modalVisible &&
        <TasksModal
          key="33"
          item={item}
          edit={edit}
          project={this.props.project}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
          tenant={this.props.tenant}
        />
    ])
  }
}