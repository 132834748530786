import React from 'react';
import { Button, Form, Input, Modal, DatePicker, Switch, message, Select } from 'antd';
import firebase from '../constants/firebase';
import moment from 'moment';
import i18n from 'i18n-js';
const { TextArea } = Input;
const { Option } = Select;

class TasksModal extends React.Component {
  state = {
    loading: true,
    containsDeadline: false,
    containsAssignee: false,
    employees: []
  }

  componentDidMount = async () => {
    this.setState({tenant: ''})
    if (this.props.edit) {
      this.setState({
        selectedColor: this.props.item.color,
        containsAssignee: this.props.item.containsAssignee, 
        containsDeadline: this.props.item.containsDeadline
      })
    }
    this.loadEmployees()
  }

  loadEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    this.unsubscribeEmployees = db.collection(`tenants/${tenant}/users`)
      .where("isActive", "==", true)
      .where("isAdmin", "==", true)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  addTask = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { name, date, containsDeadline, containsAssignee, assignee, notes } = values
    const tenant = localStorage.getItem('tenant');
    let element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      name: name,
      createdAt: new Date(),
      containsDeadline: containsDeadline || false,
      containsAssignee: containsAssignee || false,
      done: false
    }
    if (containsDeadline) {
      element.deadline = moment(date, 'DD.MM.YYYY').toDate()
    }
    if (notes) {
      element.notes = notes
    }
    if (assignee) {
      const assigneeRef = db.collection(`tenants/${tenant}/users`).doc(assignee)
      element.assignee = assigneeRef
    }
    return db.collection(`tenants/${tenant}/tasks`).add(element)
    .then(() => {
      this.setState({saving: false})
      return this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  updateItem = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { name, date, containsDeadline, notes, containsAssignee, assignee, } = values
    const tenant = localStorage.getItem('tenant');
    const ref = db.collection(`tenants/${tenant}/tasks`).doc(this.props.item.uid)

    let element = {
      name: name,
      containsDeadline: containsDeadline,
      containsAssignee: containsAssignee
    }

    if (containsDeadline) {
      element.deadline = moment(date, 'DD.MM.YYYY').toDate()
    }
    if (notes) {
      element.notes = notes
    }
    if (assignee) {
      const assigneeRef = db.collection(`tenants/${tenant}/users`).doc(assignee)
      element.assignee = assigneeRef
    }
    return ref.update(element)
      .then(() => {
        message.info(i18n.t('companyDetailsUpdated'));
        this.setState({saving: false})
        return this.props.toogleModal(false)
      })
      .catch((error) => {
        message.error(i18n.t('oops'));
        console.error("Error updating document: ", error)
      })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          return this.updateItem(values)
        } else {
          return this.addTask(values)
        }
      }
    });
  }

  handleChange = (checked, e) => {
    this.setState({
      containsDeadline: checked,
    });
  }

  handleAssigneeChange = (checked, e) => {
    this.setState({
      containsAssignee: checked,
    });
  }

  render = () => {
    const { saving, containsDeadline, containsAssignee, employees } = this.state
    const { item, edit } = this.props
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editTaskScreenHL') : i18n.t('addTaskScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('task')}>
            {getFieldDecorator('name', {
              initialValue: (item) ? item.name : '',
              rules: [{ required: true }],
            })(
              <Input
                placeholder={i18n.t('task')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('notes')}>
            {getFieldDecorator('notes', {
              initialValue: (item) ? item.notes : '',
            })(
              <TextArea 
                rows={4} 
                placeholder={i18n.t('notes')}
              />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('containsAssignee')}>
            {getFieldDecorator('containsAssignee', {
              valuePropName: 'checked',
              initialValue: (item) ? item.containsAssignee : false,
            })(
              <Switch onChange={this.handleAssigneeChange} />,
            )}
          </Form.Item>
          {containsAssignee && 
            <Form.Item label={i18n.t('assignee')}>
              {getFieldDecorator('assignee', {
                initialValue: (item) ? item.assignee.id : '',
                rules: [{ required: false }],
              })(
                <Select placeholder={i18n.t('selectEmployee')}>
                  {employees.map((item) => (
                    <Option key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          }

          <Form.Item label={i18n.t('containsDeadline')}>
            {getFieldDecorator('containsDeadline', {
              valuePropName: 'checked',
              initialValue: (item) ? item.containsDeadline : false,
            })(
              <Switch onChange={this.handleChange} />,
            )}
          </Form.Item>
          {containsDeadline && 
            <Form.Item label={i18n.t('deadline')}>
              {getFieldDecorator('date', {
                initialValue: (item && item.deadline) ? moment(item.deadline.toDate()) : moment(),
              })(
                <DatePicker format="DD.MM.YYYY" />,
              )}
            </Form.Item>
          }

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="tasks-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'material_modal' })(TasksModal);