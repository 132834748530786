import React from 'react';
import { PageHeader, Input, Button, Icon, Table, Spin, Popconfirm } from 'antd';
import firebase from '../constants/firebase';
import ClientsModal from '../modal/ClientsModal';
import i18n from 'i18n-js';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';

export default class Clients extends React.Component {
  state = {
    clients: [],
    loading: true
  }

  componentDidMount = () => {
    this.loadClients()
  }

  loadClients = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/clients`)
      .where('active', '==', true)
      .orderBy('lastname')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let clients = []
        querySnapshot.forEach((doc) => {
          let client = {
            id: doc.id,
            key: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            phone: doc.data().phone,
            street: doc.data().street,
            zip: doc.data().zip,
            city: doc.data().city,
            company: doc.data().company,
            active: doc.data().active
          }
          clients.push(client)
          this.setState({
            clients: clients,
            loading: false
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  }

  confirm = (e) => {
    this.toggleItemStatus(e)
  }

  toggleItemStatus = (item) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const ref = db.collection(`tenants/${tenant}/clients`).doc(item.id)

    return ref.update({
      active: !item.active
    })
  }

  render() {
    const { clients, loading, modalVisible, edit, item } = this.state

    if (loading) {
      return (<Spin/>)
    }

    const columns = [
      {
        title: i18n.t('companyName'),
        dataIndex: 'company',
        key: 'company',
        ...this.getColumnSearchProps('company'),
      },
      {
        title: i18n.t('firstname'),
        dataIndex: 'firstname',
        key: 'firstname',
        ...this.getColumnSearchProps('firstname'),
      },
      {
        title: i18n.t('lastname'),
        dataIndex: 'lastname',
        key: 'lastname',
        ...this.getColumnSearchProps('lastname'),
      },
      {
        title: i18n.t('city'),
        dataIndex: 'city',
        key: 'city',
        ...this.getColumnSearchProps('city'),
      },
      {
        title: i18n.t('action'),
        dataIndex: 'operation',
        render: (text, record) =>
        clients.length >= 1 ? (
          <div>
            <Link to={`/admin/clients/${record.id}`}>{i18n.t('open')}</Link>
            &nbsp;|&nbsp;
            <a href="#!" onClick={() => this.toogleModal(true, true, record)}>{i18n.t('edit')}</a>
            &nbsp;|&nbsp;
            <Popconfirm
              title={i18n.t('areYouSure')}
              onConfirm={() => this.confirm(record)}
              okText={i18n.t('yes')}
              cancelText={i18n.t('no')}
            >
              <a href="#!">{i18n.t('delete')}</a>
            </Popconfirm>
          </div>
        ) : null,
      },
    ];

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageClients')}
        // extra={[
        //   <Button key="1" type="primary" icon="user-add" onClick={() => this.toogleModal(true)}>
        //     {i18n.t('addClientScreenHL')}
        //   </Button>,
        // ]}
      />,
      <Table key="22" columns={columns} dataSource={clients} />,
      modalVisible &&
        <ClientsModal
          key="33"
          item={item}
          edit={edit}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
        />
      
    ])
  }
}