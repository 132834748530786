import React from 'react';
import { Button, Form, Input, Modal, message, Switch } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class ClaimModal extends React.Component {
  state = {
    loading: true,
  }

  updateClaim = (values) => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { item } = this.props
    const ref = db.collection(`tenants/${tenant}/claims`).doc(item.id)

    let element = {
      updatedAt: new Date(),
      updatedBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      active: !values.active,
    }

    if (values.costs) {
      element.costs = values.costs
    }
    if (values.claimNumber) {
      element.claimNumber = values.claimNumber
    }

    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.updateClaim(values)
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={i18n.t('addClaimInfo')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('costs')}>
            {getFieldDecorator('costs', {
              initialValue: (item) ? item.costs : '',
              rules: [{ required: false }],
            })(
              <Input
                placeholder={i18n.t('costs')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('claimNumber')}>
            {getFieldDecorator('claimNumber', {
              initialValue: (item) ? item.claimNumber : '',
              rules: [{ required: false }],
            })(
              <Input
                placeholder={i18n.t('claimNumber')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('finished')}>
            {getFieldDecorator('active', {
              valuePropName: 'checked',
              initialValue: (item) ? !item.active : true,
            })(
              <Switch />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="clients-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'claim_modal' })(ClaimModal);