import React from 'react';
import { PageHeader, Timeline } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import { sortBy } from 'lodash'

export default class History extends React.Component {
  state = {
    loading: true,
    messages: []
  }

  componentDidMount = () => {
    this.loadMessages()
  }

  loadMessages = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { claim } = this.props
    db.collection(`tenants/${tenant}/claims/${claim.id}/messages`)
      .orderBy('createdAt', 'desc')
      .get()
      .then((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        let message = {
          message: item.data().message,
          createdAt: item.data().createdAt,
          uid: item.id,
          type: item.data().type,
          createdBy: item.data().createdBy,
          data: item.data().data
        }
        if (item.data().createdBy._id) {
          message.createdBy = item.data().createdBy.name
          m.push(message)
          this.setState({
            messages: m,
          })
        } else {
          message.createdBy.get().then((snap) => {
            message.createdBy = snap.data().firstname + ' ' + snap.data().lastname
            m.push(message)
            this.setState({
              messages: m,
            })
          })
        }
        
      })
    })
  }

  renderMessages = () => {
    const { messages } = this.state

    const sortedMessages = sortBy(messages, [function(o) { return o.createdAt.toDate(); }]).reverse()

    return (
      <div>
        <Timeline style={{maxHeight: 400, overflow: 'auto'}}>
          {sortedMessages.map((item, i) => ([
            <Timeline.Item>{item.createdBy} {i18n.t(item.message, {name: item.data.label})} - {moment(item.createdAt.toDate()).fromNow()}</Timeline.Item>,
          ]))}
        </Timeline>
      </div>
    )
  }

  render() {
    return ([
      <PageHeader 
        key="11" 
      />,
      <div key="22">
        {this.renderMessages()}
      </div>
    ])
  }
}