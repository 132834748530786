import React from 'react';
import { PageHeader, Input, Button, Icon, Table, Spin, Tag } from 'antd';
import firebase from '../constants/firebase';
import InsurancesModal from '../modal/InsurancesModal';
import i18n from 'i18n-js';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { types, kfz, building } from '../constants/insurances';
import helper from '../utils/helper';
import { Link } from 'react-router-dom';
import InsurancesKFZModal from '../modal/InsurancesKFZModal';

export default class Insurances extends React.Component {
  state = {
    loading: true,
    insurances: [],
    modalVisible: false,
    searchText: ''
  };

  componentDidMount = async () => {
    this.loadCompanyDetails()
    if (this.props.currentUser.clientId) {
      this.loadInsurancesForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.isAdmin) {
      this.loadAllInsurances()
    }
  }

  loadCompanyDetails = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          createdAt: doc.data().createdAt.toDate(),
          stripe: doc.data().stripe,
          userAmount: doc.data().userAmount
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  loadInsurancesForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/insurances`)
      .where('client', '==', clientRef)
      .orderBy('type', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let insurances = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            type: doc.data().type,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            name: doc.data().name,
            fully: doc.data().fully,
            partially: doc.data().partially,
            driven: doc.data().driven,
            registeredAt: doc.data().registeredAt,
            deRegisteredAt: doc.data().deRegisteredAt,
            zip: doc.data().zip,
            city: doc.data().city
          }
          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id
            insurances.push(item)
            this.setState({
              insurances: insurances,
              loading: false
            })
          })
        })
      })
  }

  loadAllInsurances = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/insurances`)
      .orderBy('type', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let insurances = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            type: doc.data().type,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            name: doc.data().name,
            fully: doc.data().fully,
            partially: doc.data().partially,
            driven: doc.data().driven,
            registeredAt: doc.data().registeredAt,
            deRegisteredAt: doc.data().deRegisteredAt,
            zip: doc.data().zip,
            city: doc.data().city,
            contractNumber: doc.data().contractNumber
          }
          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id
            insurances.push(item)
            this.setState({
              insurances: insurances,
              loading: false
            })
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({modalVisible: val, edit: edit, item: item})
  }

  _addInsurance = () => {
    this.setState({modalVisible: true})
  }

  getUsedTrialDays = () => {
    const { companyData } = this.state
    const end = moment(new Date())
    let trialDuration = moment.duration(end.diff(companyData.createdAt)).asDays()
    if (trialDuration < 0) {
      trialDuration = 0
    }
    return Number(trialDuration).toFixed(0)
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  }

  toogleModal = (val, edit, item) => {
    this.setState({modalVisible: val, edit: edit, item: item})
  }

  render() {
    const { loading, insurances, modalVisible, edit, item, companyData } = this.state;

    if (loading) {
      return (<Spin/>)
    }

    if (!companyData) {
      return null
    }

    const columns = [
      {
        title: i18n.t('contractNumber'),
        dataIndex: 'contractNumber',
        key: 'contractNumber+id',
        sorter: (a, b) => a.contractNumber - b.contractNumber,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: i18n.t('type'),
        dataIndex: 'type',
        key: 'type+id',
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['descend', 'ascend'],
        render: type => {
          const selectedType = types.filter(item => item.id === type)[0]
          return (<span>{selectedType.label}</span>)
        },
      },
      {
        title: i18n.t('status'),
        dataIndex: 'active',
        key: 'id',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: active => {
          const label = active ? 'laufend' : 'storniert'
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('shortDesc'),
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
        render: (name, row) => {
          let label;
          switch (row.type) {
            case 1:
              label = name
              break;
            case 2:
              label = `${name}, ${row.zip} ${row.city}`
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('driven'),
        dataIndex: 'driven',
        key: 'driven',
        render: (tags, row) => {
          if (!tags) {
            return (
              <span>
                {helper.formatEuro(row.name)}
              </span>
            )
          }
          return (
            <span>
              {tags.map(tag => {
                let tagLabel
                switch (row.type) {
                  case 1:
                    tagLabel = kfz.filter(item => item.id === tag)[0]
                    break;
                  case 2:
                    tagLabel = building.filter(item => item.id === tag)[0]
                    break;
                  default:
                    break;
                }
                return (
                  <Tag key={tag}>
                    {tagLabel.label}
                  </Tag>
                );
              })}
            </span>
          )
        }
      },
      {
        title: i18n.t('info'),
        dataIndex: 'info',
        key: 'info+id',
        ...this.getColumnSearchProps('info'),
      },
      {
        title: 'Aktion',
        dataIndex: 'operation',
        render: (text, record) =>
        insurances.length >= 1 && this.props.currentUser.isAdmin ? (
            <div>
              <Link to={`/insurances/${record.id}`}>{i18n.t('open')}</Link>
              &nbsp;|&nbsp;
              <a href="#!" onClick={() => this.toogleModal(true, true, record)}>{i18n.t('edit')}</a>
            </div>
          ) : (
            record.type === 1 ? (
              <div>
                <Link to={`/insurances/${record.id}`}>{i18n.t('open')}</Link>
                &nbsp;|&nbsp;
                <a href="#!" onClick={() => this.toogleModal(true, true, record)}>{i18n.t('edit')}</a>
              </div>
            ) : (
              <div>
                <Link to={`/insurances/${record.id}`}>{i18n.t('open')}</Link>
              </div>
            )
          )
      },
    ];

    if (this.props.currentUser.isAdmin) {
      columns.unshift({
        title: i18n.t('client'),
        dataIndex: 'client',
        key: 'client',
        filterMultiple: false,
        sorter: (a, b) => a.clientId - b.clientId,
        sortDirections: ['descend', 'ascend'],
        render: client => (
          client && <span>{client.company}</span>
        ),
      })
    }

    return ([
      // (this.getUsedTrialDays() > 30 && !companyData.stripe) &&
      //   <div key="111">
      //     <Alert message={i18n.t('tialExpiredUserMessage')} type="error" />
      //     <br/>
      //   </div>,
      <PageHeader 
        key="11" 
        title={i18n.t('overviewScreenHL')} 
        extra={[
          this.props.currentUser.isAdmin &&
          <Button key="1" type="primary" icon="plus" onClick={this._addInsurance}>
            {i18n.t('addInsurance')}
          </Button>,
        ]}
      />,
      <Table key="22" columns={columns} dataSource={insurances} />,
      (modalVisible && this.props.currentUser.isAdmin) &&
        <InsurancesModal
          key={33}
          edit={edit}
          item={item}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />,

      (modalVisible && this.props.currentUser.isManager) &&
        <InsurancesKFZModal
          key={33}
          edit={edit}
          item={item}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ]);
  }
}