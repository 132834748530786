import React from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import { DatePicker } from 'antd';
import { TimePicker } from 'antd';
import moment from 'moment';
import { Radio } from 'antd';
import { merge } from 'lodash';
const { TextArea } = Input;
const { Option } = Select;

class AddClaimForm extends React.Component {
  state = {
    loading: true,
    formValues: {},
    users: []
  }

  componentDidMount = () => {
    this.loadEmployees()
  }

  loadEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            users: employees,
            loading: false
          })
        })
      })
  }

  addClaim = () => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { typeId, clientId, claimType, policeReference, creator, secondAssignee } = this.props;
    const { formValues } = this.state;
    
    const element = {
      date: moment(formValues.date, 'DD.MM.YYYY').toDate(),
      time: moment(formValues.time, 'HH:mm:ss').toDate(),
      createdAt: new Date(),
      active: true,
      claimType: claimType || '',
      policeReference: policeReference || '',
      claimLocation: formValues.claimLocation || '',
      claimViolated : formValues.claimViolated || '',
      info: formValues.info || '',
      claimAlcohol: formValues.claimAlcohol || false,
      claimLicence: formValues.claimLicence || false,
      claimRemoved: formValues.claimRemoved || false,
      driver: formValues.driver || '',
      opponentNumberPlate: formValues.opponentNumberPlate || '',
      opponentInsuranceCompany: formValues.opponentInsuranceCompany || '',
      opponentInsuranceNumber: formValues.opponentInsuranceNumber || '',
      opponentFirstname: formValues.opponentFirstname || '',
      opponentLastname: formValues.opponentLastname || '',
      opponentStreet: formValues.opponentStreet || '',
      opponentZip: formValues.opponentZip || '',
      opponentCity: formValues.opponentCity || '',
      opponentPhone: formValues.opponentPhone || '',
      renter: formValues.renter || '',
      roughEstimate: formValues.roughEstimate || '',
      insurance: db.doc(`tenants/${tenant}/insurances/${typeId}`),
      createdBy: db.doc(`tenants/${tenant}/users/${creator}`),
      secondAssignee: db.doc(`tenants/${tenant}/users/${secondAssignee}`),
      client: db.doc(`tenants/${tenant}/clients/${clientId}`),
      claimMaschine: formValues.claimMaschine || ''
    }
    try {
      return db.collection(`tenants/${tenant}/claims`).add(element)
      .then(() => {
        // this.props.toogleModal(false)
        this.setState({saving: false})
        message.info(i18n.t('companyDetailsUpdated'));
        this.props.history.push('/claims')
      })
      .catch((error) => {
        message.error(i18n.t('oops'));
        console.error("Error updating document: ", error)
      })
    } catch (error) {
      console.log(error)
    }
    
    
  }

  handleSubmit = e => {
    e.preventDefault();
    const { current, stepsForType } = this.props;
    const { formValues } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.next()
        console.log('Received values of form: ', values);
        const merged = merge(formValues, values);
        this.setState({formValues: merged});
        if (current === stepsForType.length - 1) {
          this.addClaim()
        }
      }
    });
  };

  render = () => {
    const { saving, users } = this.state;
    const { current, stepsForType } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="material-form">

        {(this.props.type === '1' && current === 1) &&
          <div>
            <Form.Item label={i18n.t('claimDate')}>
              {getFieldDecorator('date', {
                rules: [{ required: true }],
              })(
                <DatePicker format='DD.MM.YYYY' />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimTime')}>
              {getFieldDecorator('time', {
                rules: [{ required: true }],
              })(
                <TimePicker defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
              )}
            </Form.Item>

            <Form.Item label={i18n.t('claimLocation')}>
              {getFieldDecorator('claimLocation', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('claimLocation')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimViolated')}>
              {getFieldDecorator('claimViolated', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('claimViolated')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('damageDescription')}>
              {getFieldDecorator('info', {
                rules: [{ required: true }],
              })(
                <TextArea 
                  rows={4} 
                  placeholder={i18n.t('damageDescription')} 
                />
              )}
            </Form.Item>

            <Form.Item label={i18n.t('claimAlcohol')}>
              {getFieldDecorator('claimAlcohol', {
                rules: [{ required: true }],
              })(
                <Radio.Group>
                  <Radio value={false}>{i18n.t('no')}</Radio>
                  <Radio value={true}>{i18n.t('yes')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimLicence')}>
              {getFieldDecorator('claimLicence', {
                rules: [{ required: true }],
              })(
                <Radio.Group>
                  <Radio value={false}>{i18n.t('no')}</Radio>
                  <Radio value={true}>{i18n.t('yes')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimRemoved')}>
              {getFieldDecorator('claimRemoved', {
                rules: [{ required: true }],
              })(
                <Radio.Group>
                  <Radio value={false}>{i18n.t('no')}</Radio>
                  <Radio value={true}>{i18n.t('yes')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </div>
        }
        {(this.props.type === '1' && current === 2) &&
          <div>
            <Form.Item label={i18n.t('selectDriverPlaceholder')}>
              {getFieldDecorator('driver', {
                rules: [{ required: true }],
              })(
                <Select placeholder={i18n.t('selectDriverPlaceholder')}>
                  {users.map((item) => (
                    <Option key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
        }
        {(this.props.type === '1' && current === 3) &&
          <div>
            <Form.Item label={i18n.t('opponentNumberPlate')}>
              {getFieldDecorator('opponentNumberPlate', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('opponentNumberPlate')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentInsuranceCompany')}>
              {getFieldDecorator('opponentInsuranceCompany', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('opponentInsuranceCompany')}
                />,
              )}
            </Form.Item>

            <Form.Item label={i18n.t('opponentInsuranceNumber')}>
              {getFieldDecorator('opponentInsuranceNumber', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('opponentInsuranceNumber')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentFirstname')}>
              {getFieldDecorator('opponentFirstname', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('opponentFirstname')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentLastname')}>
              {getFieldDecorator('opponentLastname', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentLastname')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentStreet')}>
              {getFieldDecorator('opponentStreet', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentStreet')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentZip')}>
              {getFieldDecorator('opponentZip', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentZip')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentCity')}>
              {getFieldDecorator('opponentCity', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentCity')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentPhone')}>
              {getFieldDecorator('opponentPhone', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentPhone')} 
                />
              )}
            </Form.Item>
          </div>
        }

        {this.props.type === '2' &&
          <div>
            <Form.Item label={i18n.t('claimDate')}>
              {getFieldDecorator('date', {
                rules: [{ required: true }],
              })(
                <DatePicker />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimTime')}>
              {getFieldDecorator('time', {
                rules: [{ required: true }],
              })(
                <TimePicker defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('renter')}>
              {getFieldDecorator('renter', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('renter')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('roughEstimate')}>
              {getFieldDecorator('roughEstimate', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('roughEstimate')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('damageDescription')}>
              {getFieldDecorator('info', {
                rules: [{ required: true }],
              })(
                <TextArea 
                  rows={4} 
                  placeholder={i18n.t('damageDescription')} 
                />
              )}
            </Form.Item>
          </div>
        }
        
        {(Number(this.props.type) > 2 && current === 1) &&
          <div>
            <Form.Item label={i18n.t('claimDate')}>
              {getFieldDecorator('date', {
                rules: [{ required: true }],
              })(
                <DatePicker placeholder={i18n.t('claimDate')} />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('claimTime')}>
              {getFieldDecorator('time', {
                rules: [{ required: false }],
              })(
                <TimePicker placeholder={i18n.t('claimTime')} defaultOpenValue={moment('14:00:00', 'HH:mm:ss')} />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('roughEstimate')}>
              {getFieldDecorator('roughEstimate', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('roughEstimate')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('damageDescription')}>
              {getFieldDecorator('info', {
                rules: [{ required: true }],
              })(
                <TextArea 
                  rows={4} 
                  placeholder={i18n.t('damageDescription')} 
                />
              )}
            </Form.Item>
          </div>
        }

        {(Number(this.props.type) > 2 && Number(this.props.type) < 7 && current === 1) &&
          <div>
            <Form.Item label={i18n.t('claimLocation')}>
              {getFieldDecorator('claimLocation', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('claimLocation')}
                />,
              )}
            </Form.Item>
          </div>
        }

        {(Number(this.props.type) > 2 && Number(this.props.type) < 7 && current === 2) &&
          <div>
            <Form.Item label={i18n.t('opponentFirstname')}>
              {getFieldDecorator('opponentFirstname', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('opponentFirstname')}
                />,
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentLastname')}>
              {getFieldDecorator('opponentLastname', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentLastname')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentStreet')}>
              {getFieldDecorator('opponentStreet', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentStreet')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentZip')}>
              {getFieldDecorator('opponentZip', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentZip')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentCity')}>
              {getFieldDecorator('opponentCity', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentCity')} 
                />
              )}
            </Form.Item>
            <Form.Item label={i18n.t('opponentPhone')}>
              {getFieldDecorator('opponentPhone', {
                rules: [{ required: true }],
              })(
                <Input 
                  placeholder={i18n.t('opponentPhone')} 
                />
              )}
            </Form.Item>
          </div>
        }

        {(Number(this.props.type) > 8) &&
          <div>
            <Form.Item label={i18n.t('claimMaschine')}>
              {getFieldDecorator('claimMaschine', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('claimMaschine')}
                />,
              )}
            </Form.Item>
          </div>
        }

        <div className="steps-action">
          {current < stepsForType.length - 1 && (
            <Button type="primary" htmlType="submit">
              {i18n.t('next')}
            </Button>
          )}
          {current === stepsForType.length - 1 && (
            <Button type="primary" loading={saving} htmlType="submit" className="clients-form-button">
              {current === stepsForType.length - 1 &&
                i18n.t('save')
              }
            </Button>
          )}
          {current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.props.prev()}>
              {i18n.t('prev')}
            </Button>
          )}
        </div>
      </Form>
    )
  }
}

export default Form.create({ name: 'claim_form' })(AddClaimForm);