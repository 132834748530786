import React from 'react';
import { Tabs, PageHeader, Spin, Statistic, Descriptions, Button } from 'antd';
import firebase from '../constants/firebase';
import Informations from '../components/Claim/Informations';
import History from '../components/Claim/History';
import i18n from 'i18n-js';
import Comments from '../components/Claim/Comments';
import Documents from '../components/Claim/Documents';
import moment from 'moment';
import helper from '../utils/helper';
import ClaimModal from '../modal/ClaimModal';
import { types } from '../constants/insurances';
const { TabPane } = Tabs;

export default class ClaimsDetail extends React.Component {
  state = {
    loading: true,
    activeTab: '1',
    modalVisible: false
  }

  componentDidMount = () => {
    this.init()
  }

  componentDidUpdate = (newProps) => {
    if (newProps.match.params.id === this.state.claimId) {
      return false
    }

    this.init()
  }

  init = () => {
    const claimId = this.props.location.pathname.split('claims/')[1]
    this.setState({claimId: claimId}, () => {
      this.loadClaimDetail()
    })
    let params = (new URL(document.location)).searchParams
    let tab = params.get('tab')
    if (tab) {
      this.setState({activeTab: tab})
    }
  }

  loadClaimDetail = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { claimId } = this.state;

    if (claimId) {
      db.collection(`tenants/${tenant}/claims`)
        .doc(claimId)
        .onSnapshot((doc) => {
          let item = {
            id: doc.id,
            client: doc.data().client,
            insurance: doc.data().insurance,
            info: doc.data().info,
            active: doc.data().active,
            driven: doc.data().driven,
            date: doc.data().date.toDate(),
            time: doc.data().time.toDate(),
            roughEstimate: doc.data().roughEstimate,
            createdAt: doc.data().createdAt.toDate(),
            createdBy: doc.data().createdBy,
            claimType: doc.data().claimType,
            policeReference: doc.data().policeReference,
            claimLocation: doc.data().claimLocation,
            claimViolated : doc.data().claimViolated,
            claimAlcohol: doc.data().claimAlcohol,
            claimLicence: doc.data().claimLicence,
            claimRemoved: doc.data().claimRemoved,
            driver: doc.data().driver,
            opponentNumberPlate: doc.data().opponentNumberPlate,
            opponentInsuranceCompany: doc.data().opponentInsuranceCompany,
            opponentInsuranceNumber: doc.data().opponentInsuranceNumber,
            opponentFirstname: doc.data().opponentFirstname,
            opponentLastname: doc.data().opponentLastname,
            opponentStreet: doc.data().opponentStreet,
            opponentZip: doc.data().opponentZip,
            opponentCity: doc.data().opponentCity,
            opponentPhone: doc.data().opponentPhone,
            renter: doc.data().renter,
            costs: doc.data().costs,
            claimNumber: doc.data().claimNumber,
            claimMaschine: doc.data().claimMaschine,
            secondAssignee: doc.data().secondAssignee,
          }

          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            item.insurance.get().then((isnap) => {
              item.insurance = isnap.data()

              item.createdBy.get().then((isnap) => {
                item.createdBy = isnap.data()

                if (item.secondAssignee) {
                  item.secondAssignee.get().then((isnap) => {
                    item.secondAssignee = isnap.data()
    
                    this.setState({
                      claim: item,
                      loading: false
                    })
                  })
                } else {
                  this.setState({
                    claim: item,
                    loading: false
                  })
                }
              })
            })
          })
        })
    }
  }

  onTabClick = (key) => {
    this.setState({activeTab: key})
    this.props.history.push({
      search: `?tab=${key}`
    })
  }

  extraContent = () => {
    const { claim } = this.state

    return (
      <div
        style={{
          display: 'flex',
          width: 'max-content',
          justifyContent: 'flex-end',
          flexWrap: 'wrap'
        }}
      >
        <Statistic
          title="Status"
          value={claim.active ? i18n.t('active') : i18n.t('finished')}
          style={{
            marginRight: 32,
          }}
        />
        <Statistic title="Schadenhöhe" value={helper.formatEuro(claim.costs)} />
        {this.props.currentUser.isAdmin &&
          <Button onClick={() => this.toogleModal(true, true, claim)} type="primary" style={{flexBasis: 100}}>{i18n.t('edit')}</Button>
        }
      </div>
    )
  }

  renderShortContent = () => {
    const { claim } = this.state

    return (
    <Descriptions size="small" column={1}>
      <Descriptions.Item label={i18n.t('createdAt')}>
        {moment(claim.createdAt).format('DD.MM.YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('createdBy')}>
        {`${claim.createdBy.firstname} ${claim.createdBy.lastname}`}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('companyName')}>
        {claim.client.company}
      </Descriptions.Item>
      {claim.secondAssignee &&
        <Descriptions.Item label={i18n.t('claimSecondAssignee')}>
          {`${claim.secondAssignee.firstname} ${claim.secondAssignee.lastname}`}
        </Descriptions.Item>
      }
    </Descriptions>
    )
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val, 
      edit: edit, 
      item: item
    })
  }

  getTitle = () => {
    const { claim } = this.state
    let title = ''
    const insuranceType = types.filter(item => item.id === claim.insurance.type)[0].label
    switch (claim.insurance.type) {
      case 1:
        title = claim.insurance.name
        break;
      case 2:
        title = claim.insurance.name
        break;
      case 3:
        title = insuranceType
        break;
      case 4:
        title = insuranceType
        break;
      case 5:
        title = insuranceType
        break;
      case 6:
        title = insuranceType
        break;
      case 7:
        title = insuranceType
        break;
      case 8:
        title = insuranceType
        break;
      case 9:
        title = claim.claimMaschine
        break;
      case 10:
        title = claim.claimMaschine
        break;
      case 11:
        title = claim.claimMaschine
        break;
      default:
        title = claim.claimMaschine
        break;
    }
    return <div>{`${title}`}</div>
  }

  render() {
    const { loading, claim, activeTab, edit, item, modalVisible } = this.state

    if (loading) {
      return (
        <Spin />
      )
    }

    return ([
      <PageHeader 
        onBack={() => this.props.history.push('/claims')}
        key="11" 
        title={this.getTitle()}
        subTitle={claim.claimNumber}
      />,
      <div key="1112">
        <div style={{display: 'flex', marginBottom: 20}}>
          {this.renderShortContent()}
          <div style={{width: '70%'}}>
            {this.extraContent()}
          </div>
        </div>
        <Tabs key="22" defaultActiveKey="1" activeKey={activeTab} onTabClick={this.onTabClick}>
          <TabPane tab={i18n.t('informations')} key="1">
            <Informations
              claim={claim}
              currentUser={this.props.currentUser}
              active={claim.active}
            />
          </TabPane>
          <TabPane tab={i18n.t('documents')} key="2">
            <Documents
              claim={claim.id}
              active={claim.active}
            />
          </TabPane>
          <TabPane tab={i18n.t('comments')} key="3">
            <Comments
              claim={claim.id}
              clientId={claim.clientId}
              active={claim.active}
            />
          </TabPane>
          <TabPane tab={i18n.t('history')} key="4">
            <History
              claim={claim}
              active={claim.active}
            />
          </TabPane>
        </Tabs>
        {modalVisible &&
          <ClaimModal
            edit={edit}
            item={item}
            modalVisible={modalVisible}
            toogleModal={this.toogleModal}
          />
        }
      </div>
    ]);
  }
}