import React from 'react';
import { PageHeader, Input, Button, Icon, Spin, Table } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { types } from '../constants/insurances';
import helper from '../utils/helper';

export default class Claims extends React.Component {
  state = {
    loading: true,
    claims: [],
    modalVisible: false,
  };

  componentDidMount = () => {
    if (this.props.currentUser.clientId && this.props.currentUser.isManager) {
      this.loadClaimsForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.clientId && !this.props.currentUser.isManager) {
      this.loadClaimsForUser()
    } else if (this.props.currentUser.isAdmin) {
      this.loadAllClaims()
    }
  }

  loadClaimsForUser = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const userRef = db.doc(`tenants/${tenant}/users/${this.props.currentUser.id}`)

    db.collection(`tenants/${tenant}/claims`)
      .where('createdBy', '==', userRef)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let claims = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            client: doc.data().client,
            insurance: doc.data().insurance,
            info: doc.data().info,
            active: doc.data().active,
            driven: doc.data().driven,
            date: doc.data().date.toDate(),
            time: doc.data().time.toDate(),
            renter: doc.data().renter,
            roughEstimate: doc.data().roughEstimate,
            createdAt: doc.data().createdAt.toDate(),
            opponentFirstname: doc.data().opponentFirstname,
            opponentLastname: doc.data().opponentLastname,
            costs: doc.data().costs,
            claimNumber: doc.data().claimNumber
          }

          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            item.insurance.get().then((isnap) => {
              item.insurance = isnap.data()
              item.insuranceId = isnap.id

              claims.push(item)
              this.setState({
                claims: claims,
                loading: false
              })
            })
          })
        })
      })

    db.collection(`tenants/${tenant}/claims`)
      .where('secondAssignee', '==', userRef)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let claims = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            client: doc.data().client,
            insurance: doc.data().insurance,
            info: doc.data().info,
            active: doc.data().active,
            driven: doc.data().driven,
            date: doc.data().date.toDate(),
            time: doc.data().time.toDate(),
            renter: doc.data().renter,
            roughEstimate: doc.data().roughEstimate,
            createdAt: doc.data().createdAt.toDate(),
            opponentFirstname: doc.data().opponentFirstname,
            opponentLastname: doc.data().opponentLastname,
            costs: doc.data().costs,
            claimNumber: doc.data().claimNumber
          }

          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            item.insurance.get().then((isnap) => {
              item.insurance = isnap.data()
              item.insuranceId = isnap.id

              claims.push(item)
              this.setState({
                claims: this.state.claims.concat([claims])
              })
            })
          })
        })
      })
  }

  loadClaimsForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/claims`)
      .where('client', '==', clientRef)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let claims = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            client: doc.data().client,
            insurance: doc.data().insurance,
            info: doc.data().info,
            active: doc.data().active,
            driven: doc.data().driven,
            date: doc.data().date.toDate(),
            time: doc.data().time.toDate(),
            renter: doc.data().renter,
            roughEstimate: doc.data().roughEstimate,
            createdAt: doc.data().createdAt.toDate(),
            opponentFirstname: doc.data().opponentFirstname,
            opponentLastname: doc.data().opponentLastname,
            costs: doc.data().costs,
            claimNumber: doc.data().claimNumber
          }

          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            item.insurance.get().then((isnap) => {
              item.insurance = isnap.data()
              item.insuranceId = isnap.id

              claims.push(item)
              this.setState({
                claims: claims,
                loading: false
              })
            })
          })
        })
      })
  }

  loadAllClaims = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/claims`)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let claims = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            client: doc.data().client,
            insurance: doc.data().insurance,
            info: doc.data().info,
            active: doc.data().active,
            driven: doc.data().driven,
            date: doc.data().date.toDate(),
            time: doc.data().time.toDate(),
            renter: doc.data().renter,
            roughEstimate: doc.data().roughEstimate,
            createdAt: doc.data().createdAt.toDate(),
            opponentFirstname: doc.data().opponentFirstname,
            opponentLastname: doc.data().opponentLastname,
            costs: doc.data().costs,
            claimNumber: doc.data().claimNumber
          }

          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            item.insurance.get().then((isnap) => {
              item.insurance = isnap.data()
              item.insuranceId = isnap.id

              claims.push(item)
              this.setState({
                claims: claims,
                loading: false
              })
            })
          })
        })
      })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  }

  render() {
    const { loading, claims } = this.state;

    if (loading) {
      return (<Spin/>)
    }

    const columns = [
      {
        title: i18n.t('claimNumber'),
        dataIndex: 'claimNumber',
        key: 'claimNumber',
        filterMultiple: false,
        sorter: (a, b) => a.claimNumber - b.claimNumber,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: i18n.t('claimDate'),
        dataIndex: 'date',
        key: 'date',
        filterMultiple: false,
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['descend', 'ascend'],
        render: date => (
          date && <span>{moment(date).format('DD.MM.YYYY')}</span>
        ),
      },
      {
        title: i18n.t('status'),
        dataIndex: 'active',
        key: 'active',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active) => {
          const label = active ? i18n.t('active') : i18n.t('finished')
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('type'),
        dataIndex: 'active',
        key: 'type',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          const selectedType = types.filter(item => item.id === row.insurance.type)[0]
          return (<span>{selectedType.label}</span>)
        },
      },
      {
        title: i18n.t('shortDesc'),
        dataIndex: 'name',
        key: 'name',
        // ...this.getColumnSearchProps('name'),
        render: (active, row) => {
          let label;
          switch (row.insurance.type) {
            case 1:
              label = row.insurance.name
              break;
            case 2:
              label = `${row.insurance.name}, ${row.insurance.zip} ${row.insurance.city}`
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('estimate'),
        dataIndex: 'costs',
        key: 'costs',
        render: costs => (
          costs && <span>{helper.formatEuro(costs)}</span>
        ),
      },
      {
        title: `${i18n.t('renter')} / ${i18n.t('opponent')}`,
        dataIndex: 'active',
        key: 'renter',
        sorter: (a, b) => a.active.length - b.active.length,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          let label
          switch (row.insurance.type) {
            case 1:
              label = `${row.opponentFirstname} ${row.opponentLastname}`
              break;
            case 2:
              label = row.renter ? row.renter : ''
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('action'),
        dataIndex: 'operation',
        render: (text, record) =>
        claims.length >= 1 ? (
            <Link to={`/claims/${record.id}`}>{i18n.t('open')}</Link>
          ) : null,
      },
    ];

    if (this.props.currentUser.isAdmin) {
      columns.unshift({
        title: i18n.t('client'),
        dataIndex: 'client',
        key: 'client',
        filterMultiple: false,
        sorter: (a, b) => a.clientId - b.clientId,
        sortDirections: ['descend', 'ascend'],
        render: client => (
          client && <span>{client.company}</span>
        ),
      })
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('claimsScreenHL')} 
        extra={[
          <Button key="1" type="primary" icon="plus" onClick={() => this.props.history.push('/addClaim')}>
            {i18n.t('addClaim')}
          </Button>,
        ]}
      />,
      <Table key="22" columns={columns} dataSource={claims} />
    ]);
  }
}