import React from 'react';
import { Button } from 'antd';
import i18n from 'i18n-js';
import { Link } from "react-router-dom";
// import intro1 from '../walkthrough1.png';
// import intro2 from '../walkthrough2.png';
// import intro3 from '../walkthrough3.png';

class Welcome extends React.Component {
  render() {
    return (
      <div className="welcome-form">
        <p>{i18n.t('welcome')}</p>
        {/* <Carousel>
          <div>
            <h3>{i18n.t('introChatDesc')}</h3>
            <img src={intro1} alt="" />
          </div>
          <div>
            <h3>{i18n.t('introTimeDesc')}</h3>
            <img src={intro2} alt="" />
          </div>
          <div>
            <h3>{i18n.t('introTasksTitle')}</h3>
            <img src={intro3} alt="" />
          </div>
        </Carousel> */}
        <Button type="primary">
          <Link to="/">{i18n.t('start')}</Link>
        </Button>
      </div>
    );
  }
}

export default Welcome;