import React from 'react';
import { PageHeader, Spin, Statistic, Descriptions, List, Button, Tabs, Table, Tag, Input, Icon } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import EmployeesModal from '../modal/EmployeesModal';
import InsurancesModal from '../modal/InsurancesModal';
import helper from '../utils/helper';
import Chat from '../components/Chat/Chat';
import { kfz, building, types } from '../constants/insurances';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import UserAvatar from '../components/UserAvatar';
const { TabPane } = Tabs;

export default class ClientDetail extends React.Component {
  state = {
    loading: true,
    activeTab: '1',
    modalVisible: false,
    editModalVisible: false,
    client: {},
    claims: [],
    insurances: []
  }

  componentDidMount = () => {
    const clientId = this.props.location.pathname.split('clients/')[1]
    this.setState({clientId: clientId}, () => {
      this.loadClientDetail()
      this.loadClaimsForClient()
      this.loadInsurancesForClient()
      this.loadEmployees()
    })
  }

  loadClaimsForClient = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { clientId } = this.state;
    const clientRef = firebase.firestore().collection(`tenants/${tenant}/clients`).doc(clientId)

    if (clientId) {
      db.collection(`tenants/${tenant}/claims`)
        .where('client', '==', clientRef)
        .get()
        .then((docs) => {
          const claims = []
          docs.forEach(doc => {
            let item = {
              id: doc.id,
              client: doc.data().client,
              insurance: doc.data().insurance,
              info: doc.data().info,
              active: doc.data().active,
              driven: doc.data().driven,
              date: doc.data().date.toDate(),
              time: doc.data().time.toDate(),
              renter: doc.data().renter,
              roughEstimate: doc.data().roughEstimate,
              createdAt: doc.data().createdAt.toDate(),
              opponentFirstname: doc.data().opponentFirstname,
              opponentLastname: doc.data().opponentLastname,
              costs: doc.data().costs,
              claimNumber: doc.data().claimNumber
            }
  
            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
  
              item.insurance.get().then((isnap) => {
                item.insurance = isnap.data()
                item.insuranceId = isnap.id
  
                claims.push(item)
                this.setState({
                  claims: claims,
                  loading: false
                })
              })
            })
          });
        })
    }
  }

  loadInsurancesForClient = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { clientId } = this.state;
    const clientRef = firebase.firestore().collection(`tenants/${tenant}/clients`).doc(clientId)

    if (clientId) {
      db.collection(`tenants/${tenant}/insurances`)
        .where('client', '==', clientRef)
        .where('active', '==', true)
        .get()
        .then((docs) => {
          const insurances = []
          docs.forEach(doc => {
            let item = {
              id: doc.id,
              type: doc.data().type,
              client: doc.data().client,
              info: doc.data().info,
              active: doc.data().active,
              name: doc.data().name,
              fully: doc.data().fully,
              partially: doc.data().partially,
              driven: doc.data().driven,
              registeredAt: doc.data().registeredAt,
              deRegisteredAt: doc.data().deRegisteredAt,
              zip: doc.data().zip,
              city: doc.data().city,
              contractNumber: doc.data().contractNumber
            }
            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
              insurances.push(item)
              this.setState({
                insurances: insurances,
                loading: false
              })
            })
          });
        })
    }
  }

  loadClientDetail = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { clientId } = this.state;

    if (clientId) {
      db.collection(`tenants/${tenant}/clients`)
        .doc(clientId)
        .onSnapshot((doc) => {
          let item = {
            id: doc.id,
            company: doc.data().company,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            street: doc.data().street,
            zip: doc.data().zip,
            city: doc.data().city,
            phone: doc.data().phone,
            email: doc.data().email,
            createdAt: doc.data().createdAt.toDate(),
          }

          this.setState({
            client: item,
            loading: false
          })
        })
    }
  }

  loadEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const { clientId } = this.state;
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/users`)
      .where('client', '==', clientRef)
      .onSnapshot((querySnapshot) => {
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar,
            phone: doc.data().phone,
            street: doc.data().street,
            zip: doc.data().zip,
            city: doc.data().city,
            isManager: doc.data().isManager
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  extraContent = () => {
    const { claims, insurances } = this.state

    return (
      <div
        style={{
          display: 'flex',
          width: 'max-content',
          justifyContent: 'flex-end',
          flexWrap: 'wrap'
        }}
      >
        <Statistic
          title="Schäden Gesamt"
          value={claims.length}
          style={{
            marginRight: 32,
          }}
        />
        <Statistic 
          title="Laufende Versicherungen" 
          value={insurances.length} 
        />
      </div>
    )
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val, 
      edit: edit, 
      item: item
    })
  }

  toogleEditModal = (val, edit, item) => {
    this.setState({
      editModalVisible: val, 
      edit: edit, 
      item: item
    })
  }

  renderShortContent = () => {
    const { client } = this.state

    return (
    <Descriptions size="small" column={1}>
      <Descriptions.Item label={i18n.t('createdAt')}>
        {moment(client.createdAt).format('DD.MM.YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('companyName')}>
        {client.company}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('contactPerson')}>
        {`${client.firstname} ${client.lastname}`}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('street')}>
        {client.street}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('zip')}>
        {client.zip}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('city')}>
        {client.city}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('phone')}>
        {client.phone}
      </Descriptions.Item>
      <Descriptions.Item label={i18n.t('email')}>
        {client.email}
      </Descriptions.Item>
    </Descriptions>
    )
  }

  renderEmployees = () => {
    const { employees, loading } = this.state
    return (
      <List
        itemLayout="horizontal"
        dataSource={employees}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[<a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>]}
          >
            <List.Item.Meta
              avatar={
                <UserAvatar
                  size="30"
                  name={`${item.firstname} ${item.lastname}`}
                  src={item.avatar}
                />
             }
              title={`${item.email} ${item.isManager ? '(Manger)' : ''} ${item.isAdmin ? '(Administrator)' : ''}`}
              description={`${item.lastname}, ${item.firstname}`}
              style={!item.isActive ? {opacity: 0.5} : {opacity: 1}}
            />
          </List.Item>
        )}
      />
    )
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  }

  renderOverview = () => {
    const { insurances } = this.state

    const columns = [
      {
        title: i18n.t('contractNumber'),
        dataIndex: 'contractNumber',
        key: 'contractNumber+id',
        sorter: (a, b) => a.contractNumber - b.contractNumber,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: i18n.t('type'),
        dataIndex: 'type',
        key: 'type+id',
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['descend', 'ascend'],
        render: type => {
          const selectedType = types.filter(item => item.id === type)[0]
          return (<span>{selectedType.label}</span>)
        },
      },
      {
        title: i18n.t('status'),
        dataIndex: 'active',
        key: 'id',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: active => {
          const label = active ? 'laufend' : 'storniert'
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('shortDesc'),
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
        render: (name, row) => {
          let label;
          switch (row.type) {
            case 1:
              label = name
              break;
            case 2:
              label = `${name}, ${row.zip} ${row.city}`
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('driven'),
        dataIndex: 'driven',
        key: 'driven',
        render: (tags, row) => {
          if (!tags) {
            return (
              <span>
                {helper.formatEuro(row.name)}
              </span>
            )
          }
          return (
            <span>
              {tags.map(tag => {
                let tagLabel
                switch (row.type) {
                  case 1:
                    tagLabel = kfz.filter(item => item.id === tag)[0]
                    break;
                  case 2:
                    tagLabel = building.filter(item => item.id === tag)[0]
                    break;
                  default:
                    break;
                }
                return (
                  <Tag key={tag}>
                    {tagLabel.label}
                  </Tag>
                );
              })}
            </span>
          )
        }
      },
      {
        title: i18n.t('info'),
        dataIndex: 'info',
        key: 'info+id',
        ...this.getColumnSearchProps('info'),
      },
      {
        title: 'Aktion',
        dataIndex: 'operation',
        render: (text, record) =>
        insurances.length >= 1 && this.props.currentUser.isAdmin ? (
            <a href="#!" onClick={() => this.toogleEditModal(true, true, record)}>{i18n.t('edit')}</a>
          ) : null,
      },
    ];

    return (
      <Table key="22" columns={columns} dataSource={insurances} />
    )
  }

  renderClaims = () => {
    const { claims } = this.state

    const columns = [
      {
        title: i18n.t('claimNumber'),
        dataIndex: 'claimNumber',
        key: 'claimNumber',
        filterMultiple: false,
        sorter: (a, b) => a.claimNumber - b.claimNumber,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: i18n.t('claimDate'),
        dataIndex: 'date',
        key: 'date',
        filterMultiple: false,
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['descend', 'ascend'],
        render: date => (
          date && <span>{moment(date).format('DD.MM.YYYY')}</span>
        ),
      },
      {
        title: i18n.t('status'),
        dataIndex: 'active',
        key: 'active',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active) => {
          const label = active ? i18n.t('active') : i18n.t('finished')
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('type'),
        dataIndex: 'active',
        key: 'type',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          const selectedType = types.filter(item => item.id === row.insurance.type)[0]
          return (<span>{selectedType.label}</span>)
        },
      },
      {
        title: i18n.t('shortDesc'),
        dataIndex: 'name',
        key: 'name',
        // ...this.getColumnSearchProps('name'),
        render: (active, row) => {
          let label;
          switch (row.insurance.type) {
            case 1:
              label = row.insurance.name
              break;
            case 2:
              label = `${row.insurance.name}, ${row.insurance.zip} ${row.insurance.city}`
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('estimate'),
        dataIndex: 'costs',
        key: 'costs',
        render: costs => (
          costs && <span>{helper.formatEuro(costs)}</span>
        ),
      },
      {
        title: `${i18n.t('renter')} / ${i18n.t('opponent')}`,
        dataIndex: 'active',
        key: 'renter',
        sorter: (a, b) => a.active.length - b.active.length,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          let label
          switch (row.insurance.type) {
            case 1:
              label = `${row.opponentFirstname} ${row.opponentLastname}`
              break;
            case 2:
              label = row.renter ? row.renter : ''
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('action'),
        dataIndex: 'operation',
        render: (text, record) =>
        claims.length >= 1 ? (
            <Link to={`/claims/${record.id}`}>{i18n.t('open')}</Link>
          ) : null,
      },
    ];

    return (
      <Table key="22" columns={columns} dataSource={claims} />
    )
  }

  render() {
    const { loading, client, edit, item, modalVisible, editModalVisible } = this.state

    if (loading) {
      return (
        <Spin />
      )
    }

    return ([
      <PageHeader 
        key="11" 
        onBack={() => window.history.back()}
        title={client.company || ''}
        subTitle={`${client.firstname || ''} ${client.lastname || ''}`}
      />,
      <div key="1112">
        <div style={{display: 'flex', marginBottom: 20}}>
          {this.renderShortContent()}
          <div style={{width: '70%'}}>
            {this.extraContent()}
          </div>
        </div>
        <br/>
        <Tabs defaultActiveKey="1">
          <TabPane tab={i18n.t('overview')} key="1">
           {this.renderOverview()}
          </TabPane>
          <TabPane tab={i18n.t('claimsScreenHL')} key="2">
            {this.renderClaims()}
          </TabPane>
          <TabPane tab={i18n.t('chatScreenHL')} key="3">
            <Chat
              key="22"
              chat={`clients/${client.id}/groupChat`}
              active={true}
            />
          </TabPane>
          <TabPane tab={i18n.t('employee')} key="4">
            <PageHeader 
              key="23" 
              extra={[
                <Button key="1" type="primary" icon="plus" onClick={() => this.toogleModal(true)}>
                  {i18n.t('addEmployee')}
                </Button>,
              ]}
            />
            {this.renderEmployees()}
          </TabPane>
        </Tabs>
    
        {editModalVisible &&
          <InsurancesModal
            edit={edit}
            item={item}
            modalVisible={editModalVisible}
            toogleModal={this.toogleEditModal}
          />
        }
        
        {modalVisible &&
          <EmployeesModal
            edit={edit}
            item={item}
            clientId={client.id}
            modalVisible={modalVisible}
            toogleModal={this.toogleModal}
          />
        }
      </div>
    ]);
  }
}