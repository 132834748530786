import React from 'react';
import { Button, Form, Input, Modal, Select, message, Switch, DatePicker } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import { types, building, kfz } from '../constants/insurances';
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;

class InsurancesModal extends React.Component {
  state = {
    loading: true,
    clients: [],
    modalVisible: true,
    selectedType: 0
  }

  componentDidMount = async () => {
    this.loadClients()
    if (this.props.edit) {
      this.setState({selectedType: this.props.item.type})
    }
  }

  loadClients = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');

    return db.collection(`tenants/${tenant}/clients`)
      .orderBy('lastname', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({loading: false})
        }
        let clients = []
        querySnapshot.forEach((doc) => {
          let client = {
            id: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            city: doc.data().city,
            company: doc.data().company,
          }
          clients.push(client)
          this.setState({
            clients: clients,
            loading: false
          })
        })
      })
  }

  updateInsurance = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const ref = db.collection(`tenants/${tenant}/insurances`).doc(this.props.item.id)

    let element = {
      updatedAt: new Date(),
      updatedBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      type: values.type,
      name: values.name,
      client: db.doc(`tenants/${tenant}/clients/${values.client}`),
      active: values.active,
      contractNumber: values.contractNumber
    }

    if (values.fully) {
      element.fully = values.fully
    }
    if (values.partially) {
      element.partially = values.partially
    }
    if (values.driven) {
      element.driven = values.driven
    }
    if (values.registeredAt) {
      element.registeredAt = moment(values.registeredAt, 'DD.MM.YYYY').toDate()
    }
    if (values.deRegisteredAt) {
      element.deRegisteredAt = moment(values.deRegisteredAt, 'DD.MM.YYYY').toDate()
    }
    if (values.zip) {
      element.zip = values.zip
    }
    if (values.city) {
      element.city = values.city
    }
    if (values.info) {
      element.info = values.info
    }
    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  addInsurance = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const element = {
      createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      name: values.name,
      client: db.doc(`tenants/${tenant}/clients/${values.client}`),
      active: values.active,
      type: values.type,
      info: values.info,
      contractNumber: values.contractNumber
    }
    
    if (values.fully) {
      element.fully = values.fully
    }
    if (values.partially) {
      element.partially = values.partially
    }
    if (values.driven) {
      element.driven = values.driven
    }
    if (values.registeredAt) {
      element.registeredAt = moment(values.registeredAt, 'DD.MM.YYYY').toDate()
    }
    if (values.deRegisteredAt) {
      element.deRegisteredAt = moment(values.deRegisteredAt, 'DD.MM.YYYY').toDate()
    }
    if (values.zip) {
      element.zip = values.zip
    }
    if (values.city) {
      element.city = values.city
    }

    try {
      return db.collection(`tenants/${tenant}/insurances`).add(element)
        .then(() => {
          this.setState({saving: false})
          this.props.toogleModal(false)
        })
        .catch((error) => {
          message.error(i18n.t('oops'));
          console.error("Error updating document: ", error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateInsurance(values)
        } else {
          this.addInsurance(values)
        }
      }
    });
  }

  onTypeDropdownChange = (val) => {
    this.setState({selectedType: val})
  }

  render = () => {
    const { clients, saving, selectedType } = this.state
    const { item, edit } = this.props
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editInsurance') : i18n.t('addInsurance')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="insurance-form">
          <Form.Item label={i18n.t('selectClientPlaceholder')}>
            {getFieldDecorator('client', {
              initialValue: (item) ? item.clientId : '',
              rules: [{ required: true }],
            })(
              <Select placeholder={i18n.t('selectClientPlaceholder')}>
                {clients.map((item) => (
                  <Option key={item.id} value={item.id}>{`${item.company} | ${item.firstname} ${item.lastname}`}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={i18n.t('selectTypePlaceholder')}>
            {getFieldDecorator('type', {
              initialValue: (item) ? item.type : '',
              rules: [{ required: true }],
            })(
              <Select placeholder={i18n.t('selectTypePlaceholder')} onChange={this.onTypeDropdownChange}>
                {types.map((item) => (
                  <Option key={item.id} value={item.id}>{item.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={i18n.t('contractNumber')}>
              {getFieldDecorator('contractNumber', {
                initialValue: (item) ? item.contractNumber : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('contractNumber')}
                />,
              )}
            </Form.Item>

          {/* kfz */}
          {selectedType === 1 && [
            <Form.Item key="11" label={i18n.t('driven')}>
              {getFieldDecorator('driven', {
                initialValue: (item) ? item.driven : undefined,
                rules: [{ required: true }],
              })(
                <Select mode="multiple" placeholder={i18n.t('driven')}>
                  {kfz.map((item) => (
                    <Option key={item.id} value={item.id}>{item.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>,
            <Form.Item key="22" label={i18n.t('numberPlate')}>
              {getFieldDecorator('name', {
                initialValue: (item) ? item.name : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('numberPlate')}
                />,
              )}
            </Form.Item>,
            <Form.Item key="33" label={i18n.t('partiallyComprehensive')}>
              {getFieldDecorator('partially', {
                initialValue: (item) ? item.partially : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('partiallyComprehensive')}
                />,
              )}
            </Form.Item>,
            <Form.Item key="44" label={i18n.t('fullyComprehensive')}>
              {getFieldDecorator('fully', {
                initialValue: (item) ? item.fully : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('fullyComprehensive')}
                />,
              )}
            </Form.Item>
          ]}

          {/* building */}
          {selectedType === 2 && [
            <Form.Item key="11" label={i18n.t('driven')}>
              {getFieldDecorator('driven', {
                initialValue: (item) ? item.driven : undefined,
                rules: [{ required: true }],
              })(
                <Select mode="multiple" placeholder={i18n.t('driven')}>
                  {building.map((item) => (
                    <Option key={item.id} value={item.id}>{item.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>,
            <Form.Item key="22" label={i18n.t('street')}>
              {getFieldDecorator('name', {
                initialValue: (item) ? item.name : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('street')}
                />,
              )}
            </Form.Item>,
            <Form.Item key="33" label={i18n.t('zip')}>
              {getFieldDecorator('zip', {
                initialValue: (item) ? item.zip : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('zip')}
                />,
              )}
            </Form.Item>,
            <Form.Item key="44" label={i18n.t('city')}>
              {getFieldDecorator('city', {
                initialValue: (item) ? item.city : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('city')}
                />,
              )}
            </Form.Item>
          ]}

          {selectedType > 2 &&
            <Form.Item key="22" label={i18n.t('insuranceSum')}>
              {getFieldDecorator('name', {
                initialValue: (item) ? item.name : '',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('insuranceSum')}
                />,
              )}
            </Form.Item>
          }

          <Form.Item label={i18n.t('info')}>
            {getFieldDecorator('info', {
              initialValue: (item) ? item.info : '',
            })(
              <TextArea 
                rows={4} 
                placeholder={i18n.t('info')}
              />,
            )}
          </Form.Item>

          {/* kfz */}
          {selectedType === 1 && [
            <Form.Item key="555" label={i18n.t('status')}>
              {getFieldDecorator('active', {
                valuePropName: 'checked',
                initialValue: (item) ? item.active : true,
              })(
                <Switch />,
              )}
            </Form.Item>,
            <Form.Item key="556" label={i18n.t('registeredAt')}>
              {getFieldDecorator('registeredAt', {
                initialValue: (item) ? moment(item.registeredAt.toDate()) : moment(),
                rules: [{ required: true }],
              })(
                <DatePicker
                  format='DD.MM.YYYY'
                  placeholder={i18n.t('registeredAt')}
                />,
              )}
            </Form.Item>,
            <Form.Item key="557" label={i18n.t('deRegisteredAt')}>
              {getFieldDecorator('deRegisteredAt', {
                initialValue: (item) ? moment(item.deRegisteredAt.toDate()) : moment(),
                rules: [{ required: false }],
              })(
                <DatePicker
                  format='DD.MM.YYYY'
                  placeholder={i18n.t('deRegisteredAt')}
                />,
              )}
            </Form.Item>
          ]}

          {/* all exept of kfz */}
          {selectedType !== 1 &&
            <Form.Item label={i18n.t('status')}>
              {getFieldDecorator('active', {
                valuePropName: 'checked',
                initialValue: (item) ? item.active : true,
              })(
                <Switch />,
              )}
            </Form.Item>
          }

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="insurance-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'insurances_modal' })(InsurancesModal);