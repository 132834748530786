import React from 'react';
import firebase from '../../constants/firebase';
import { Comment, Form, Button, List, Input, Spin, Upload, Modal } from 'antd';
import moment from 'moment';
import mime from 'mime-types';
import UserAvatar from '../UserAvatar';

const Editor = ({ onChange, onSubmit, isSending, value, uploadProps }) => {
  return (
    <div>
      <Form.Item>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
          <Input disabled={isSending} onPressEnter={onSubmit} onChange={onChange} value={value} />
          <Upload key="1" {...uploadProps} style={{marginLeft: 10, marginRight: 10}}>
            <Button shape="circle" icon="cloud-upload" />
          </Upload>
          <Button disabled={!value || isSending} shape="circle" icon="caret-right" htmlType="submit" loading={isSending} onClick={onSubmit} type="primary" />
        </div>
      </Form.Item>
    </div>
  )
};

export default class Chat extends React.Component {
  state = {
    messages: [],
    userData: {},
    isSending: false,
    userModal: false
  }

  componentDidMount = async () => {
    this.loadUserData()
    this.loadFirstMessages()
  }

  componentWillUnmount = () => {
    this.unsubscribeFirstMessages()
  }

  loadFirstMessages = () => {
    const tenant = localStorage.getItem('tenant');
    const { chat } = this.props
    const db = firebase.firestore()
    this.unsubscribeFirstMessages = db.collection(`tenants/${tenant}/${chat}`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((message) => {
        m.push({
          _id: Math.round(Math.random() * 1000000),
          text: message.data().text,
          createdAt: message.data().createdAt.toDate(),
          user: message.data().user,
          image: message.data().image,
          location: message.data().location,
          audio: message.data().audio,
          duration: message.data().duration,
          video: message.data().video
        })
      })
      this.setState({messages: m})
      this.scrollToBottom();
    })
  }

  loadUserData = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const currentUser = firebase.auth().currentUser.uid
    let userData = {}
    db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((doc) => {
        userData = {
          firstname: doc.data().firstname || "",
          lastname: doc.data().lastname || "",
          avatar: doc.data().avatar || "",
          allowNotifications: doc.data().allowNotifications || false,
        }
        this.setState({userData: userData})
      })
  }

  handleSubmit = (a, b, downloadURL, filename, mimeType) => {
    const { value, userData } = this.state
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');

    if (!value && !downloadURL) {
      return false
    }

    this.setState({
      isSending: true,
      displayEmoji: false
    });

    const message = {
      user: {
        avatar: userData.avatar,
        name: `${userData.firstname} ${userData.lastname}`,
        _id: firebase.auth().currentUser.uid
      },
      createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      _id: Math.round(Math.random() * 1000000),
    }

    if (downloadURL) {
      message.fileName = filename
      message.mimeType = mimeType
      if (mimeType.indexOf('image') >= 0) {
        message.image = downloadURL
      } else if (mimeType.indexOf('video') >= 0) {
        message.video = downloadURL
      }
    } else {
      message.text = value
    }
    
    this.onSend(message)
    this.setState({
      value: '',
      isSending: false
    })
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView();
  }

  onSend = (message) => {
    const { chat } = this.props
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    return db.collection(`tenants/${tenant}/${chat}`).add(message)
  }
  
  uploadImage = async(uri) => {
    const tenant = localStorage.getItem('tenant');
    this.setState({
      isSending: true,
    });
    this.scrollToBottom();
    const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1);
    const mimeType = mime.lookup(fileType)
    this.setState({uploading: true})
    const metadata = {
      contentType: mimeType,
    }
    const name = uri.originFileObj.name.replace(/\s/g,'-')
    const ref = firebase.storage().ref().child(`tenants/${tenant}/groupChat/${name}.${fileType}`)
    return ref.put(uri.originFileObj, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({uploading: false})
        return this.handleSubmit('', '', downloadURL, name, mimeType)
      })
    })
  }

  renderCommentList = (messages) => (
    <List
      dataSource={messages}
      itemLayout="horizontal"
      renderItem={item => (
        <List.Item
          key={item._id}
        >
          <List.Item.Meta
            avatar={
              <UserAvatar
                size="30"
                name={`${item.user.name}`}
                src={item.user.avatar}
              />
            }
            title={item.user.name}
            description={moment(item.createdAt).format('DD.MM.YYYY HH:mm')}
          />
          {this.renderMessage(item)}
        </List.Item>
      )}
    />
  )
  
  renderMessage = (message) => {
    return (
      <Comment
        content={this.renderContent(message)}
      />
    )
  }

  renderContent = (content) => {
    if (content.image) {
      return (
        <div>
          <img src={content.image} onClick={() => this.openModal(content.image)} width="250" alt="" />
        </div>
      )
    } else if (content.audio) {
      return (
        <div>
          <audio controls>
            <source src={content.audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )
    } else if (content.video) {
      return (
        <div>
          <video controls>
            <source src={content.video} type="video/mp4" />
            Your browser does not support the audio element.
          </video>
        </div>
      )
    } else {
      return (content.text)
    }
  }

  openModal = (doc) => {
    this.setState({modalVisible: true, modalDoc: doc})
  }

  closeModal = () => {
    this.setState({modalVisible: false})
  }
  
  render() {
    const userId = firebase.auth() && firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
    const { messages, userData, isSending, value, modalVisible, modalDoc } = this.state

    if (!userId) {
      return (<Spin />)
    }

    const upload = (file) => {
      this.uploadImage(file)
    }
    
    const uploadProps = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          // console.log(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div>
        <div className="chat_wrapper">
          {messages.length > 0 && this.renderCommentList(messages)}
          <div 
            style={{ float:"left", clear: "both" }}
            ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
        {this.props.active &&
          <Comment
            avatar={
              <UserAvatar
                size="30"
                name={`${userData.firstname} ${userData.lastname}`}
                src={userData.avatar}
              />
            }
            content={
              <Editor
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                submitting={isSending}
                value={value}
                uploadProps={uploadProps}
              />
            }
          />
        }
        <Modal key="33" visible={modalVisible} footer={null} onCancel={this.closeModal}>
          <img alt="" style={{ width: '100%' }} src={modalDoc} />
        </Modal>
      </div>
    );
  }
}