import React from 'react';
import { PageHeader, Spin, Descriptions, Tag, Tabs, Table } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import helper from '../utils/helper';
import { kfz, building, types } from '../constants/insurances';
import { Link } from 'react-router-dom';
const { TabPane } = Tabs;

export default class insurancesDetail extends React.Component {
  state = {
    loading: true,
    insurance: {},
    claims: []
  }

  componentDidMount = () => {
    const insuranceId = this.props.location.pathname.split('insurances/')[1]
    this.setState({insuranceId: insuranceId}, () => {
      this.loadInsuranceDetail()
      this.loadClaimsForInsurance()
    })
  }

  loadInsuranceDetail = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { insuranceId } = this.state;

    if (insuranceId) {
      db.collection(`tenants/${tenant}/insurances`)
        .doc(insuranceId)
        .onSnapshot((doc) => {
          let item = {
            id: doc.id,
            type: doc.data().type,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            name: doc.data().name,
            fully: doc.data().fully,
            partially: doc.data().partially,
            driven: doc.data().driven,
            registeredAt: doc.data().registeredAt,
            deRegisteredAt: doc.data().deRegisteredAt,
            zip: doc.data().zip,
            city: doc.data().city,
            createdBy: doc.data().createdBy,
            contractNumber: doc.data().contractNumber
          }
          item.client.get().then((snap) => {
            item.client = snap.data()
            item.clientId = snap.id

            if (item.createdBy) {
              item.createdBy.get().then((isnap) => {
                item.createdBy = isnap.data()
  
                this.setState({
                  insurance: item,
                  loading: false
                })
              })
            } else {
              this.setState({
                insurance: item,
                loading: false
              })
            }
          })
        })
    }
  }

  loadClaimsForInsurance = () => {
    const db = firebase.firestore()
    const tenant = localStorage.getItem('tenant');
    const { insuranceId } = this.state;
    const insuranceRef = firebase.firestore().collection(`tenants/${tenant}/insurances`).doc(insuranceId)

    if (insuranceId) {
      db.collection(`tenants/${tenant}/claims`)
        .where('insurance', '==', insuranceRef)
        .get()
        .then((docs) => {
          const claims = []
          docs.forEach(doc => {
            let item = {
              id: doc.id,
              client: doc.data().client,
              insurance: doc.data().insurance,
              info: doc.data().info,
              active: doc.data().active,
              driven: doc.data().driven,
              date: doc.data().date.toDate(),
              time: doc.data().time.toDate(),
              renter: doc.data().renter,
              roughEstimate: doc.data().roughEstimate,
              createdAt: doc.data().createdAt.toDate(),
              opponentFirstname: doc.data().opponentFirstname,
              opponentLastname: doc.data().opponentLastname,
              costs: doc.data().costs,
              claimNumber: doc.data().claimNumber
            }
  
            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
  
              item.insurance.get().then((isnap) => {
                item.insurance = isnap.data()
                item.insuranceId = isnap.id
  
                claims.push(item)
                this.setState({
                  claims: claims,
                })
              })
            })
          });
        })
    }
  }

  renderShortContent = () => {
    const { insurance } = this.state

    return (
      <Descriptions size="small" column={1}>
        <Descriptions.Item label={i18n.t('createdAt')}>
          {moment(insurance.createdAt).format('DD.MM.YYYY')}
        </Descriptions.Item>
        {insurance.createdBy &&
          <Descriptions.Item label={i18n.t('createdBy')}>
            {`${insurance.createdBy.firstname} ${insurance.createdBy.lastname}`}
          </Descriptions.Item>
        }
        {insurance.client &&
          <Descriptions.Item label={i18n.t('companyName')}>
            {insurance.client.company}
          </Descriptions.Item>
        }
      </Descriptions>
    )
  }

  getDriven = () => {
    const { insurance } = this.state
    const tags = insurance.driven

    if (!tags) {
      return (
        <span>
          {helper.formatEuro(insurance.name)}
        </span>
      )
    }
    return (
      <span>
        {tags.map(tag => {
          let tagLabel
          switch (insurance.type) {
            case 1:
              tagLabel = kfz.filter(item => item.id === tag)[0]
              break;
            case 2:
              tagLabel = building.filter(item => item.id === tag)[0]
              break;
            default:
              break;
          }
          return (
            <Tag key={tag}>
              {tagLabel.label}
            </Tag>
          );
        })}
      </span>
    )
  }

  getShortDesc = () => {
    const { insurance } = this.state
    let shortDesc;
    switch (insurance.type) {
      case 1:
        shortDesc = insurance.name
        break;
      case 2:
        shortDesc = `${insurance.name}, ${insurance.zip} ${insurance.city}`
        break;
      default:
        break;
    }
    return shortDesc
  }

  renderOverview = () => {
    const { insurance } = this.state
    console.log(insurance)
    const selectedType = types.filter(item => item.id === insurance.type)[0].label
    const activeLabel = insurance.active ? 'laufend' : 'storniert'

    return (
      <Descriptions size="small" column={1}>
        <Descriptions.Item label={i18n.t('type')}>
          {selectedType}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('status')}>
          {activeLabel}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('shortDesc')}>
          {this.getShortDesc()}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('driven')}>
          {this.getDriven()}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('info')}>
          {insurance.info}
        </Descriptions.Item>

        {insurance.type === 1 && [
          <Descriptions.Item key="1" label={i18n.t('fully')}>
            {helper.formatEuro(insurance.fully)}
          </Descriptions.Item>,
          <Descriptions.Item key="2" label={i18n.t('partially')}>
            {helper.formatEuro(insurance.partially)}
          </Descriptions.Item>
        ]}

      </Descriptions>
    )
  }

  renderClaims = () => {
    const { claims } = this.state

    const columns = [
      {
        title: i18n.t('claimNumber'),
        dataIndex: 'claimNumber',
        key: 'claimNumber',
        filterMultiple: false,
        sorter: (a, b) => a.claimNumber - b.claimNumber,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: i18n.t('claimDate'),
        dataIndex: 'date',
        key: 'date',
        filterMultiple: false,
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['descend', 'ascend'],
        render: date => (
          date && <span>{moment(date).format('DD.MM.YYYY')}</span>
        ),
      },
      {
        title: i18n.t('status'),
        dataIndex: 'active',
        key: 'active',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active) => {
          const label = active ? i18n.t('active') : i18n.t('finished')
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('type'),
        dataIndex: 'active',
        key: 'type',
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          const selectedType = types.filter(item => item.id === row.insurance.type)[0]
          return (<span>{selectedType.label}</span>)
        },
      },
      {
        title: i18n.t('shortDesc'),
        dataIndex: 'name',
        key: 'name',
        // ...this.getColumnSearchProps('name'),
        render: (active, row) => {
          let label;
          switch (row.insurance.type) {
            case 1:
              label = row.insurance.name
              break;
            case 2:
              label = `${row.insurance.name}, ${row.insurance.zip} ${row.insurance.city}`
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        }
      },
      {
        title: i18n.t('estimate'),
        dataIndex: 'costs',
        key: 'costs',
        render: costs => (
          costs && <span>{helper.formatEuro(costs)}</span>
        ),
      },
      {
        title: `${i18n.t('renter')} / ${i18n.t('opponent')}`,
        dataIndex: 'active',
        key: 'renter',
        sorter: (a, b) => a.active.length - b.active.length,
        sortDirections: ['descend', 'ascend'],
        render: (active, row) => {
          let label
          switch (row.insurance.type) {
            case 1:
              label = `${row.opponentFirstname} ${row.opponentLastname}`
              break;
            case 2:
              label = row.renter ? row.renter : ''
              break;
            default:
              break;
          }
          return (<span>{label}</span>)
        },
      },
      {
        title: i18n.t('action'),
        dataIndex: 'operation',
        render: (text, record) =>
        claims.length >= 1 ? (
            <Link to={`/claims/${record.id}`}>{i18n.t('open')}</Link>
          ) : null,
      },
    ];

    return (
      <Table key="22" columns={columns} dataSource={claims} />
    )
  }

  render() {
    const { loading, insurance, insuranceId } = this.state

    if (loading || !insuranceId) {
      return (
        <Spin />
      )
    }

    return ([
      <PageHeader 
        onBack={() => this.props.history.push('/')}
        key="11" 
        title={insurance.contractNumber || ''}
        subTitle={`${insurance.zip || ''} ${insurance.city || ''}`}
      />,
      <div key="1112">
        <div style={{display: 'flex', marginBottom: 20}}>
          {this.renderShortContent()}
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab={i18n.t('overview')} key="1">
           {this.renderOverview()}
          </TabPane>
          <TabPane tab={i18n.t('claimsScreenHL')} key="2">
            {this.renderClaims()}
          </TabPane>
        </Tabs>
      </div>
    ]);
  }
}