import React from 'react'
import { Form, Icon, Input, Button, Alert, Checkbox } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import { Link } from 'react-router-dom';

class Register extends React.Component {
  state = {
    tenant: '',
    error: ''
  }

  componentDidMount = () => {
    const tenant = localStorage.getItem('tenant');
    if (!tenant) {
      return this.props.history.push('/choose-workspace')
    }
    this.setState({tenant: tenant})
  }

  SignUp = async (values) => {
    if (!values.dataProtection || !values.email || !values.password) {
      return false
    }

    const tenant = localStorage.getItem('tenant');
    const { firstname, lastname, phone, email, company } = values
    const db = firebase.firestore()
    this.setState({fetching: true})

    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then(async (userData) => {
          const user = await firebase.auth().currentUser

          db
            .collection(`tenants/${tenant}/clients`)
            .add({
              email,
              company: company ? company : `${firstname} ${lastname}`,
              city: '',
              street: '',
              zip: '',
              phone,
              firstname,
              lastname,
              active: true,
              createdAt: new Date(),
              private: company ? false : true
            })
            .then((res) => {
              const clientRef = db.collection(`tenants/${tenant}/clients`).doc(res.id)

              return db
                .collection(`tenants/${tenant}/users`)
                .doc(user.uid)
                .set({
                  email,
                  phone,
                  client: clientRef,
                  tenant: tenant,
                  firstname,
                  lastname,
                  isActive: true,
                  isManager: true,
                  createdAt: new Date()
                })
                .then(() => {
                  this.setState({fetching: false})
                  window.location.href = '/welcome'
                  // this.props.history.push('/welcome')
                })
                .catch((error) => {
                  // console.log(error)
                  this.setState({fetching: false, error: error.toString()})
                })
            })
            .catch((error) => {
              // console.log(error)
              this.setState({fetching: false, error: error.toString()})
            })
        })
        .catch((error) => {
          this.setState({fetching: false, error: error.toString()})
          // console.log(error)
        })
    } catch (error) {
      this.setState({fetching: false, error: error.toString()})
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.SignUp(values)
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, fetching } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="register-form">
        <h3>{i18n.t('signupText')}</h3>
        <p>{i18n.t('signUpDesc')}</p>
        <Form.Item>
          {getFieldDecorator('company', {
            rules: [{ required: false, message: 'Bitte Firma angeben!' }],
          })(
            <Input
              placeholder={`${i18n.t('companyName')} (${i18n.t('optional')})`}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('firstname', {
            rules: [{ required: true, message: 'Bitte geben Sie Ihren Vornamen an!' }],
          })(
            <Input
              placeholder={i18n.t('firstname')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('lastname', {
            rules: [{ required: true, message: 'Bitte geben Sie Ihren Nachnamen an!' }],
          })(
            <Input
              placeholder={i18n.t('lastname')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Bitte geben Sie Ihre Telefonnummer an!' }],
          })(
            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('phone')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Bitte geben Sie Ihre Email an' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('email')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Bitte geben Sie ein Passwort ein!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={i18n.t('password')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('dataProtection', {
            rules: [{ required: true, message: 'Bitte aktzeptieren Sie die Datenschutzerklärung!' }],
          })(
            <Checkbox>{i18n.t('acceptDataProtection')}</Checkbox>,
          )}
          <Link to={{ pathname: '/data-protection', query: { contentId: 9 } }} style={{paddingLeft: 10, marginTop: -15, display: 'block'}}>{i18n.t('readMore')}</Link>
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
            {i18n.t('send')}
          </Button>
          <Button block>
            <Link to="/login">{i18n.t('signin')}</Link>
          </Button>
          {/* <Link to="/login">{i18n.t('signin')}</Link> */}
        </Form.Item>
        {error && 
          <Alert message={error} type="error" />
        }
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_register' })(Register);
