import React from 'react';
import { PageHeader, List, Button } from 'antd';
import firebase from '../constants/firebase';
import EmployeesModal from '../modal/EmployeesModal';
import i18n from 'i18n-js';
import UserAvatar from '../components/UserAvatar';

export default class Employees extends React.Component {
  state = {
    employees: [],
    loading: true
  }

  componentDidMount = () => {
    if (this.props.currentUser.clientId) {
      this.loadEmployeesForClient(this.props.currentUser.clientId)
    } else if (this.props.currentUser.isAdmin) {
      this.loadAdminEmployees()
    }
  }

  loadEmployeesForClient = (clientId) => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    const clientRef = db.collection(`tenants/${tenant}/clients`).doc(clientId)

    db.collection(`tenants/${tenant}/users`)
      .where('client', '==', clientRef)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isManager: doc.data().isManager,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  loadAdminEmployees = () => {
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .where('isAdmin', '==', true)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  render() {
    const { employees, loading, modalVisible, edit, item } = this.state

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageEmployees')}
        subTitle={!this.props.currentUser.clientId ? i18n.t('administrators') : ''}
        extra={[
          <Button key="1" type="primary" icon="plus" onClick={() => this.toogleModal(true)}>
            {i18n.t('addEmployee')}
          </Button>,
        ]}
      />,
      <List
        key="22"
        itemLayout="horizontal"
        dataSource={employees}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[<a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>]}
          >
            <List.Item.Meta
              avatar={
                <UserAvatar
                  size="30"
                  name={`${item.firstname} ${item.lastname}`}
                  src={item.avatar}
                />
             }
              title={`${item.email} ${item.isManager ? '(Manger)' : ''} ${item.isAdmin ? '(Administrator)' : ''}`}
              description={`${item.lastname}, ${item.firstname}`}
              style={!item.isActive ? {opacity: 0.5} : {opacity: 1}}
            />
          </List.Item>
        )}
      />,
      modalVisible &&
        <EmployeesModal
          key="33"
          item={item}
          clientId={this.props.currentUser.clientId}
          edit={edit}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
        />
    ])
  }
}