import React from 'react';
import {
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Claims from '../views/Claims';
import AddClaim from '../views/AddClaim';
import Login from '../views/Login';
import ForgotPassword from '../views/ForgotPassword';
import Register from '../views/Register';
import Tenant from '../views/Tenant';
import Profile from '../views/Profile';
import ClaimsDetail from '../views/ClaimsDetail';
import GroupChat from '../views/GroupChat';
import Company from '../views/Company';
import Clients from '../views/Clients';
import ClientsDetail from '../views/ClientsDetail';
import InsurancesDetail from '../views/InsurancesDetail';
import Employees from '../views/Employees';
// import Intro from '../views/Intro';
import WebView from '../views/WebView';
import Insurances from '../views/Insurances';
import ClientCompanyDetails from '../views/ClientCompanyDetails';
import CompanyChat from '../views/CompanyChat';
import CompanyTasks from '../views/CompanyTasks';
import Welcome from '../views/Welcome';
import moment from 'moment';

class Navigator extends React.Component {

  getUsedTrialDays = () => {
    const { companyData } = this.props
    const end = moment(new Date())
    let trialDuration = moment.duration(end.diff(companyData.createdAt)).asDays()
    if (trialDuration < 0) {
      trialDuration = 0
    }
    return Number(trialDuration).toFixed(0)
  }

  render = () => {
    const { authenticated, currentUser, companyData } = this.props
    const trialDays = authenticated ? this.getUsedTrialDays() : 0

    return (
      <Switch>
        {/* <Route path="/intro" component={Intro} /> */}
        <Route path="/choose-workspace" component={Tenant} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register" component={Register} />
        <Route path="/welcome" component={Welcome} />
        <Route path="/imprint" component={WebView} />
        <Route path="/data-protection" component={WebView} />
        <PrivateRoute path="/profile" component={Profile} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/insurances/:id" component={InsurancesDetail} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/claims/:id" component={ClaimsDetail} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/claims" component={Claims} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} home={true} />
        <PrivateRoute path="/addclaim" component={AddClaim} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/chat" component={GroupChat} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/help" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/terms-of-use" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/company" component={Company} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/internalChat" component={CompanyChat} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/internalTasks" component={CompanyTasks} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/clients/:id" component={ClientsDetail} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/clients" component={Clients} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/employees" component={Employees} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <ManagerRoute path="/manager/employees" component={Employees} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <ManagerRoute path="/manager/company/:id" component={ClientCompanyDetails} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/" component={Insurances} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
      </Switch>
    );
  }
}

function PrivateRoute({ component: Component, authenticated, currentUser, companyData, usedTrialDays, home, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          (!companyData.stripe && (companyData.userAmount > 1) && usedTrialDays > 30) ? (
            (currentUser.isAdmin) ? (
              <Redirect
                to={{
                  pathname: "/admin/abo",
                  state: { from: props.location }
                }}
              />
            ) : (
              (!home) ? (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: props.location }
                  }}
                />
              ) : (
                <Component currentUser={currentUser} {...props} />
              )
            )
          ) : (
            <Component currentUser={currentUser} {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/choose-workspace",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function AdminRoute({ component: Component, authenticated, currentUser, companyData, usedTrialDays, abo, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true && currentUser.isAdmin ? (
          (!abo && !companyData.stripe && (companyData.userAmount > 1) && usedTrialDays > 30) ? (
            <Redirect
              to={{
                pathname: "/admin/abo",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component currentUser={currentUser} {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function ManagerRoute({ component: Component, authenticated, currentUser, companyData, usedTrialDays, abo, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true && currentUser.isManager ? (
          (!abo && !companyData.stripe && (companyData.userAmount > 1) && usedTrialDays > 30) ? (
            <Redirect
              to={{
                pathname: "/admin/abo",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component currentUser={currentUser} {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default Navigator