import React from 'react';
import { PageHeader, Form } from 'antd';
import Chat from '../components/Chat/Chat';

class CompanyChat extends React.Component {
  render() {

    return ([
      <PageHeader 
        key="11" 
        title="Interner Chat"
      />,
      <Chat
        key="22"
        chat={`companyChat`}
        active={true}
      />
    ])
  }
}

export default Form.create({ name: 'company' })(CompanyChat);