import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class Tenant extends React.Component {
  state = {
    error: ''
  }

  componentDidMount = () => {
    const tenant = localStorage.getItem('tenant');
    if (this.props.location && this.props.location.search) {
      const urlParam = this.props.location.search || ''
      const tenantFromUrl = urlParam.split('?tenant=')[1]
      
      if (tenantFromUrl) {
        localStorage.setItem('tenant', tenantFromUrl);
        this.props.history.push('/register', {tenant: tenantFromUrl});
      }      
    }

    if (tenant && tenant !== '') {
      this.props.history.push('/login');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.checkTenant(values.tenant)
      }
    });
  };

  _setTenantAsync = async (tenant) => {
    localStorage.setItem('tenant', tenant);
    this.props.history.push('/login', {tenant: tenant});
  }

  checkTenant = (userTenant) => {
    if (!userTenant) {
      return false
    }
    this.setState({fetching: true})
    const db = firebase.firestore()
    db.collection(`tenants`)
      .doc(userTenant)
      .get()
      .then((docs) => {
        if (!docs.exists) {
          return this.setState({fetching: false, error: i18n.t('workspaceNotFound')})
        }
        this.setState({fetching: false})
        const tenant = userTenant
        this._setTenantAsync(tenant)
    })
    .catch((error) => {
      this.setState({fetching: false})
      console.log("error", error)
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, fetching } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="tenant-form">
        <h3>{i18n.t('selectTenant')}</h3>
        <Form.Item>
          {getFieldDecorator('tenant', {
            rules: [{ required: true }],
          })(
            <Input
              placeholder={i18n.t('tenantPlaceholder')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} type="primary" htmlType="submit" className="tenant-form-button" style={{width: '100%'}}>
            {i18n.t('next')}
          </Button>
        </Form.Item>
        <a href="https://ella-app.de/support/erste-schritte/app/allgemein-anmeldung/" rel="noopener noreferrer" target="_blank">
          {i18n.t('loginHelp')}
        </a>
        {error && 
          <Alert message={error} type="error" />
        }
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_tenant' })(Tenant);