import * as firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/messaging'

export const firebaseConfig = {
  apiKey: "AIzaSyDFvf_gIPC-vTXMnKU1QFKvm4m0bzsHXtY",
  authDomain: "versicherung-service.firebaseapp.com",
  databaseURL: "https://versicherung-service.firebaseio.com",
  projectId: "versicherung-service",
  storageBucket: "versicherung-service.appspot.com",
  messagingSenderId: "911263737297",
  appId: "1:911263737297:web:4eea63b79ababd81cdacf0",
  measurementId: "G-222110186"
}
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;