import React from 'react';
import { Spin, Card, Empty, Icon, Upload, Modal, message, Popconfirm, PageHeader } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import mime from 'mime-types';
import { Player } from 'video-react';
import moment from 'moment';
const { Meta } = Card;
const { Dragger } = Upload;

export default class Documents extends React.Component {
  state = {
    loading: true,
    documents: [],
    modalVisible: false,
    modalDoc: '',
  }

  componentDidMount = () => {
    this.loadDocumentsFromGallery()
  }

  loadDocumentsFromGallery = () => {
    const { claim } = this.props
    const tenant = localStorage.getItem('tenant');
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/claims/${claim}/gallery`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
        let m = []
        doc.docs.forEach((document) => {
          m.push({
            downloadURL: document.data().downloadURL,
            createdAt: document.data().createdAt.toDate(),
            mimeType: document.data().mimeType,
            id: document.id
          })
        })
        this.setState({documents: m, loading: false})
      })
  }

  renderDocument = (item) => {
    if (item.mimeType && item.mimeType.indexOf('pdf') >= 0) {
      return (
        <Card
          key={item.id}
          hoverable
          cover={
            <Icon type="file-pdf" style={{fontSize: 30}}/>
          }
          actions={[
            <Icon type="eye" key="setting" onClick={() => window.open(item.downloadURL, '_blank')} />,
            <Icon type="download" key="edit" />,
            <Popconfirm
              title={i18n.t('areYouSure')}
              onConfirm={() => this.deleteImage(item)}
              okText={i18n.t('yes')}
              cancelText={i18n.t('no')}
            >
              <Icon type="delete" key="ellipsis" />
            </Popconfirm>
          ]}
        >
          <Meta title="" description={moment(item.createdAt).format('DD.MM.YYYY')} />
        </Card>
      )
    }

    if (item.mimeType && item.mimeType.indexOf('video') >= 0) {
      return (
        <Card
          key={item.id}
          hoverable
          cover={
            <Player
              src={item.downloadURL}
            />
          }
          actions={[
            <Icon type="eye" key="setting" onClick={() => this.setModal(item)} />,
            <Popconfirm
              title={i18n.t('areYouSure')}
              onConfirm={() => this.deleteImage(item)}
              okText={i18n.t('yes')}
              cancelText={i18n.t('no')}
            >
              <Icon type="delete" key="ellipsis" />
            </Popconfirm>
          ]}
        >
        </Card>
      )
    }

    return (
      <Card
        key={item.id}
        hoverable
        cover={<img alt="" src={item.downloadURL} />}
        actions={[
          <Icon type="eye" key="setting" onClick={() => this.setModal(item)} />,
          <Icon type="download" key="edit" />,
          <Popconfirm
            title={i18n.t('areYouSure')}
            onConfirm={() => this.deleteImage(item)}
            okText={i18n.t('yes')}
            cancelText={i18n.t('no')}
          >
            <Icon type="delete" key="ellipsis" />
          </Popconfirm>
        ]}
      >
      </Card>
    )
  }

  uploadDocument = async(uri) => {
    const { claim } = this.props
    const tenant = localStorage.getItem('tenant');
    const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1);
    const mimeType = mime.lookup(fileType)
    const db = firebase.firestore()
    this.setState({uploading: true})
    const metadata = {
      contentType: mimeType,
    }
    const name = uri.originFileObj.name.replace(/\s/g,'-')
    const ref = firebase.storage().ref().child(`tenants/${tenant}/claims/${claim}/${name}`)
    return ref.put(uri.originFileObj, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        const document = {
          downloadURL: downloadURL,
          createdAt: new Date(),
          mimeType: mimeType,
          createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`)
        }
        this.setState({uploading: false})
        return db.collection(`tenants/${tenant}/claims/${claim}/gallery`).add(document)
      })
    })
  }

  getStorageLocationForURL = (url) => {
    let storageLocation = url.split('/o/')[1]
    storageLocation = storageLocation.split('?')[0]
    storageLocation = storageLocation.split('claims%2F')[1]
    storageLocation = storageLocation.replace('%2F', '/')
    return storageLocation 
  }

  deleteImage = (doc) => {
    const tenant = localStorage.getItem('tenant');
    const { project } = this.state
    const db = firebase.firestore()
    const storagePath = firebase.storage().ref()
    storagePath.child(`tenants/${tenant}/claims/${this.getStorageLocationForURL(doc.downloadURL)}`).delete()
    .then(() => {
      db.collection(`tenants/${tenant}/claims/${project}/gallery`).doc(doc.id).delete()
      .then(function() {
        message.info(i18n.t('hasBeenDeleted'));
      })
      .catch(function(error) {
        message.error(i18n.t('oops'));
        // console.error("Error removing document: ", error)
      })
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  closeModal = () => this.setState({ modalVisible: false });

  setModal = async file => {
    this.setState({
      modalDoc: file,
      modalIsVideo: file.video,
      modalVisible: true,
    });
  };

  render() {
    const { loading, documents, modalVisible, modalDoc, modalIsVideo } = this.state
    const { active } = this.props

    const upload = (file) => {
      this.uploadDocument(file)
    }

    const props = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // message.info(i18n.t('companyDetailsUpdated'));
        } else if (info.file.status === 'error') {
          // message.error(i18n.t('oops'));
        }
      },
    };

    if (loading) {
      return (
        <Spin />
      )
    }

    return ([
      <PageHeader 
        key="11" 
      />,
      <div key={22} className="documentList">
        {documents.map(item => (
          this.renderDocument(item)
        ))}
        {documents.length === 0 &&
          <Empty />
        }
        {active &&
          <Card
            hoverable
            cover={
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <Icon type="plus" />
                </p>
                <p className="ant-upload-text">Datei hochladen</p>
              </Dragger>
            }
          >
          </Card>
        }
      </div>,
      <Modal key="33" visible={modalVisible} footer={null} onCancel={this.closeModal}>
        {modalIsVideo &&
          <div>
            <Player
              playsInline
              src={modalIsVideo}
            />
            <p>{moment(modalDoc.createdAt).format('DD.MM.YYYY HH:mm')}</p>
          </div>
        }
        {!modalIsVideo &&
          <div>
            <img alt="" style={{ width: '100%' }} src={modalDoc.downloadURL} />
            <p>{moment(modalDoc.createdAt).format('DD.MM.YYYY HH:mm')}</p>
          </div>
        }
      </Modal>
    ]);
  }
}